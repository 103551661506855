/**
 * Endpoint for fetching list of CDUs along with their assignment data
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";
import Assignment from "model/items/Assignment";
import CDUSearchFilter from "model/utils/CDUSearchFilter";

Api.register("assignmentsSearch", ({ filter }) => {
  return axios({
    url: "/assignmentRotations",
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: {
      q: filter.query,
      pageNumber: filter.page || 1,
      isInRotationFilter: CDUSearchFilter.trilToValue[filter.inRotation],
      businessType: CDUSearchFilter.businessTypeToValue[filter.businessType],
      canCoachExperimentUsersFilter:
        CDUSearchFilter.trilToValue[filter.inExperiment],
      orderBy: filter.sortKey,
      programLocale: CDUSearchFilter.localeToValue[filter.programLocale],
      programPackage: CDUSearchFilter.packageToValue[filter.programPackage],
      coursePack: CDUSearchFilter.coursePackToValue[filter.coursePack],
    },
  }).then((response) => {
    return response.data.result.map((r) => _.pick(r, Assignment.properties));
  });
});
