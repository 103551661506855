/**
 * Endpoint for fetching CDU info
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import CDU from "model/items/CDU";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("cduOne", ({ cduAccessCode }) => {
  return axios({
    url: `/coaches/cdus/${cduAccessCode}`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((response) => {
    return _.pick(response.data, CDU.properties);
    // 'accessCode',
    // 'admin',
    // 'cduManager',
    // 'dashboardLanguage',
    // 'email',
    // 'firstName',
    // 'isActive',
    // 'isExternal',
    // 'lastName',
    // 'permissions',
    // 'ufcManager',
    // 'namelyEmployeeId',
    // 'managerAccessCode',
    // 'managerFirstName',
    // 'managerLastName',
    // 'employmentStartDate',
    // 'responsibilityAllocations',
    // 'language',
  });
});
