/**
 * Endpoint for creating employment data
 *
 * Copyright (C) 2019A Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register(
  "cduDataCreateEmployment",
  ({ cduAccessCode, employmentId, data }) => {
    return axios({
      url: `/coaches/cdus/${cduAccessCode}/employment`,
      baseURL: process.env.HIVE_SERVER_URL,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: getAuthorizationHeader(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return "ok";
      })
      .catch((error) => {
        console.log("EMPLOYMENT RAMP UP ERROR", error);
        throw error;
      });
  }
);
