/**
 * Endpoint for fetching assignment data for one CDU
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("assignmentsOne", ({ cduAccessCode }) => {
  return axios({
    url: `/assignmentRotations/cdus/${cduAccessCode}`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((response) => response.data.result);
});
