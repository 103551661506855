import { useList } from "react-use";

import { ToolLog, Logger } from "./models";

export function useToolLog() {
  const [log, { push, reset }] = useList<ToolLog>();

  const update: Logger = (value: string, error?: ToolLog["error"]) =>
    push({ value, error });

  return [log, update, reset] as const;
}
