/**
 * Model for CDU schedule
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author Hubert
*/


import _ from "lodash";
import moment from "moment";


class Schedule {

  static days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  static properties = [
    "dateStarted",
    "dateEnded",
    "id",
    "weeklyHours",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  constructor(raw) {
    _.assign(this, _.pick(raw, Schedule.properties));
  }

  getWeekdays() {
    return _.pick(this, Schedule.days);
  }

  getWeekdaysCount() {
    return _.reduce(Schedule.days, (sum, day) => sum + (this[day] ? 1 : 0), 0);
  }

  getMinimumDaysCount() {
    return Math.ceil(this.weeklyHours / 8);
  }

  isEqualTo(schedule) {
    return _.every(Schedule.properties, day => this[day] === schedule[day]);
  }

  isActive() {
    return !(this.dateEnded && moment(this.dateEnded).isBefore(moment()));
  }

}

export default Schedule;
