/**
 * Sheet cell with boolean data
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import React from "react";
import { If } from "@noom/noomscape";
import classnames from "classnames";


const SheetBoolField = ({
  label,
  value,
  editMode,
  onChange,
}) => (
  <div className="landlookSheet-field">
    <div className="landlookSheet-label">
      <If
        if={ (value === null || value === undefined) }
        then={ () => (
          <div className="landlookSheet-indicator">
            <div className="landlookSheet-indicatorInner"/>
          </div>
        ) }
      />
      { label }
    </div>
    <div className={ (value === null || value === undefined) ? "landlookSheet-noValue" : "landlookSheet-value" }>
      <If
        if={ editMode }
        then={ () => (
          <React.Fragment>
            <div
              className={ classnames({
                "landlookSheet-boolInput": true,
                "active": !!value,
              }) }
              onClick={ onChange(true) }
            >
              <div className="landlookSheet-boolInputMarker"/>
              Yes
            </div>
            <div
              className={ classnames({
                "landlookSheet-boolInput": true,
                "active": !value,
              }) }
              onClick={ onChange(false) }
            >
              <div className="landlookSheet-boolInputMarker"/>
              No
            </div>
          </React.Fragment>
        ) }
        else={ () => (
          <React.Fragment>
            { (value === null || value === undefined) ? "N/A" : (value ? "Yes" : "No") }
          </React.Fragment>
        ) }
      />
    </div>
  </div>
);


export default SheetBoolField;
