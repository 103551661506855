/**
 * Main front end point of entry.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import { createRoot } from "react-dom/client";
import App from "app/App";
import axios from "axios";
import * as noomscape from "@noom/noomscape";
import { ThemeProvider, LoaderView } from "@noom/wax-component-library";

import "api/endpoints";

import "style/reset.scss";
import "style/main.scss";
import "react-datepicker/dist/react-datepicker.css";

// Configure libraries
axios.defaults.headers.post["Content-Type"] = "application/json";
noomscape.Controller.setGlobalLoadingComponent(LoaderView);

// Render the app
const root = createRoot(document.getElementById("root"))
root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
