/**
 * Controller for the CDU page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import _ from "lodash";
import { useNavigate, useParams } from "react-router";
import PropTypes from "prop-types";
import CDUCollection from "model/collections/CDUCollection";
import CDUEditPage from "views/cdu/CDUEditPage/CDUEditPage";
import {
  Controller,
  handler,
  FormStore,
  ApiRequestsCollection,
} from "@noom/noomscape";
import Tableau from "model/items/Tableau";
import Schedule from "model/items/Schedule";

const formKey = "CDU_ALLOCATION_FORM";

// Work around for react router v6
export const withRouter = (Component) => {
  const Wrapper = (props) =>{
      const history = useNavigate();
      const params = useParams();
      return <Component history={history} params={params} {...props}/>
  }
  return Wrapper;
}

class CDUEditController extends Controller {
  static contextTypes = {
    showToast: PropTypes.func,
    showSuccess: PropTypes.func,
    showError: PropTypes.func,
  };

  mainComponent = CDUEditPage;

  title = () => "Noom Noom";

  controllerWillMount() {
    FormStore.insert(formKey, { open: false, isReady: false });
  }

  fetch = () => {
    CDUCollection.fetchOne(this.props.params.cduAccessCode, {
      complete: true,
    });
  };

  onCDUData = () => {
    const cduAccessCode = this.props.params.cduAccessCode;
    const cdu = CDUCollection.get(cduAccessCode);

    if (cdu) {
      FormStore.update(formKey, {
        open: false,
        isReady: true,

        email: cdu.email,
        namelyEmployeeId: cdu.namelyEmployeeId,
        isActive: cdu.isActive,
        managerAccessCode: cdu.managerAccessCode,

        employmentStartDate: cdu.employment?.dateStarted,
        rampUp: !!cdu.employment?.isInRampUp(),
        language: cdu.employment?.language,
        hasFixedSchedule: cdu.employment?.hasFixedSchedule,

        schedule: new Schedule(cdu.schedule),

        baselineTableau: cdu.baselineTableau
          ? cdu.baselineTableau.asFormData
          : Tableau.emptyFormData(),

        temporaryTableau: cdu.temporaryTableau?.asFormData,
      });
    }

    this.onData();
  };

  unfetch = () => {
    FormStore.remove(formKey);
  };

  subscribe = (onData) => {
    CDUCollection.addChangeListener(this.onCDUData);
    FormStore.addListener(formKey, onData);
    ApiRequestsCollection.addChangeListener(onData);
  };

  unsubscribe = (onData) => {
    CDUCollection.removeChangeListener(this.onCDUData);
    FormStore.removeListener(formKey, onData);
    ApiRequestsCollection.removeChangeListener(onData);
  };

  @handler
  onGoBack = () => {
    history.go(-1);
  };

  @handler
  onOpenForm = () => {
    FormStore.update(formKey, {
      open: true,
    });
  };

  @handler
  onCancelForm = () => {
    FormStore.update(formKey, {
      open: false,
    });
  };

  @handler
  onSaveForm = () => {
    const cdu = CDUCollection.get(this.props.params.cduAccessCode);
    const data = FormStore.get(formKey);
    const baselineErrors = [];
    const temporaryErrors = [];
    const scheduleErrors = [];

    if (data.baselineTableau) {
      if (!Tableau.isFormDataTotalCorrect(data.baselineTableau)) {
        baselineErrors.push(
          "Make sure that allocation percentages add up to 100%."
        );
      }
    }

    if (data.temporaryTableau) {
      if (!Tableau.isFormDataTotalCorrect(data.temporaryTableau)) {
        temporaryErrors.push(
          "Make sure that allocation percentages add up to 100%."
        );
      }
      if (!data.temporaryTableau?.dateStarted) {
        temporaryErrors.push("Starting date is required.");
      }
      if (!data.temporaryTableau?.dateEnded) {
        temporaryErrors.push("End date is required.");
      }
    }

    const newSchedule = new Schedule(_.assign({}, cdu.schedule, data.schedule));

    if (
      !cdu.isPerDiem() &&
      newSchedule.getMinimumDaysCount() > newSchedule.getWeekdaysCount()
    ) {
      scheduleErrors.push(
        `This coach will be employed for ${newSchedule.weeklyHours} hours,
        you need to select at least ${newSchedule.getMinimumDaysCount()} days.`
      );
    }

    FormStore.update(formKey, {
      baselineErrors,
      temporaryErrors,
      scheduleErrors,
    });

    if (
      baselineErrors.length > 0 ||
      temporaryErrors.length > 0 ||
      scheduleErrors.length > 0
    ) {
      return;
    }

    CDUCollection.updateWhiteData({
      cduAccessCode: this.props.params.cduAccessCode,
      data: data,
    })
      .then(() => {
        this.fetch();
        this.context.showSuccess();
      })
      .catch((error) => {
        console.log("CDUEditController ERROR:", error);
        this.context.showError(error.toString());
      });
  };

  @handler
  onInputChange = (key) => (event) => {
    FormStore.update(formKey, {
      [key]: event.currentTarget.value,
    });
  };

  @handler
  onNumberChange = (key) => (event) => {
    FormStore.update(formKey, {
      [key]: Number(event.currentTarget.value),
    });
  };

  @handler
  onValueChange = (key) => (value) => (event) => {
    FormStore.update(formKey, {
      [key]: value,
    });
  };

  @handler
  onToggleChange = (key) => (event) => {
    FormStore.update(formKey, {
      [key]: !_.get(FormStore.get(formKey), key),
    });
  };

  @handler
  onCreateTemporaryTableau = () => {
    FormStore.update(formKey, {
      temporaryTableau: Tableau.emptyFormData(),
    });
  };

  @handler
  onRemoveTemporaryTableau = () => {
    FormStore.update(formKey, {
      temporaryTableau: false,
    });
  };

  getData = () => {
    const cduAccessCode = this.props.params.cduAccessCode;
    return {
      cdu: CDUCollection.get(cduAccessCode),
      formData: FormStore.get(formKey),
      updateRequest: ApiRequestsCollection.get(
        `cdu.${cduAccessCode}.updateWhiteData`
      ),
    };
  };

  isReady = () => {
    return !!this.state.cdu;
  };
}

export default withRouter(CDUEditController);
