import React from "react";
import WhitePartTableau from "views/cdu/CDUEditPage/WhitePartTableau";

import "./AssignmentsAllocationsModal.scss";

const AssignmentsAllocationsModal = ({
  cdu,
  onDismiss,
}) => {
  return (
    <div className="Modal-box AssignmentsAllocationsModal-box">
      <div className="AssignmentsAllocationsModal-body">
        <div className="AssignmentsAllocationsModal-container">
          <div className="landlookGrid-box landlookSheet-box">
            <div className="landlookGrid-row">
              <div className="landlookGrid-column6">
                {cdu.baselineTableau && (
                  <WhitePartTableau
                    tableau={cdu.baselineTableau}
                    baseline={true}
                  />
                )}
              </div>
              <div className="landlookGrid-column6">
                {cdu.temporaryTableau && (
                  <WhitePartTableau
                    tableau={cdu.temporaryTableau}
                    baseline={false}
                  />
                )}
              </div>
            </div>

            <div className="AssignmentsAllocationsModal-footer">
              <div
                className="AssignmentsAllocationsModal-footerButton"
                onClick={onDismiss}
              >
                Done
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


export default AssignmentsAllocationsModal;
