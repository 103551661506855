/**
 * View for the user checker.
 *
 * Copyright (C) 2021C Noom, Inc.
 * @author nikola
 */

import React, { FC, ChangeEventHandler, useMemo } from "react";
import {
  Box,
  Button,
  Textarea,
  Collapse,
  useDisclosure,
} from "@noom/wax-component-library";

import { ScrollingLog } from "modules/tools";
import type { Result } from "./UserCheckerController";

import "./UserCheckerLayout.scss";

export type UserCheckerLayoutProps = {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onCreate: () => void;
  onReset: () => void;
  onRetryAll: () => void;
  onRetryFailed: () => void;
  isValid: boolean;
  inProgress: boolean;
  showReport: boolean;
  log: any[];
  failed: any[];
  results?: Result[];
};

const UserCheckerLayout: FC<UserCheckerLayoutProps> = (props) => {
  const {
    value,
    onChange,
    onCreate,
    onReset,
    onRetryAll,
    onRetryFailed,
    isValid,
    inProgress,
    showReport,
    log,
    failed,
    results,
  } = props;

  const unGrouped = useMemo(
    () =>
      results
        ?.filter((result) => !result.userGroup && !!result.profile)
        ?.map((result) => result.userAccessCode)
        ?.join("\n"),
    [results?.length]
  );

  const { isOpen, onToggle } = useDisclosure();

  return (
    <div className="landlookLayout-scrollPage userCheckerLayout-box">
      <div className="landlookLayout-maxWrap">
        <div className="landlookGrid-box landlookSheet-box">
          <div className="landlookGrid-row">
            <div className="landlookGrid-column12">
              <div className="landlookSheet-title">
                Group Membership Checker
              </div>
            </div>
          </div>

          <div className="landlookSheet-field">
            <div className="landlookSheet-label">Check users group status</div>

            <div className="landlookSheet-label">
              Add user access codes below. Tab or comma separated lines.
            </div>

            <div className="userCheckerLayout-textWrap">
              {inProgress || showReport ? <ScrollingLog log={log} /> : null}

              {!inProgress && !showReport ? (
                <Textarea
                  autoFocus
                  className="landlookSheet-input"
                  value={value || ""}
                  onChange={onChange}
                  isDisabled={inProgress}
                />
              ) : null}

              {showReport && (
                <Box mt={3}>
                  <Button size="sm" onClick={onToggle}>
                    Show ungrouped
                  </Button>
                  <Collapse isOpen={isOpen}>
                    <Textarea
                      isReadOnly
                      whiteSpace="pre-wrap"
                      value={unGrouped}
                    ></Textarea>
                  </Collapse>
                </Box>
              )}

              <div className="userCheckerLayout-buttonWrap">
                {showReport ? (
                  <>
                    <Button onClick={onReset} variantColor="secondary">
                      Reset
                    </Button>

                    {failed.length > 0 ? (
                      <Button onClick={onRetryFailed} variantColor="secondary">
                        Retry failed
                      </Button>
                    ) : null}
                  </>
                ) : null}

                {!showReport ? (
                  <Button
                    isDisabled={!isValid}
                    isLoading={inProgress}
                    onClick={onCreate}
                    variantColor="secondary"
                  >
                    {inProgress ? "Working..." : "Check"}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCheckerLayout;
