/**
 * Controller for the assignment page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import {
  Controller,
  FormStore,
  handler,
  ApiRequestsCollection,
} from "@noom/noomscape";
import AssignmentsCollection from "model/collections/AssignmentsCollection";
import CDUSearchFilter from "model/utils/CDUSearchFilter";

import AssignmentsHomePage from "views/assignments/AssignmentsHomePage/AssignmentsHomePage";
import Assignment from "model/items/Assignment";
import AssignmentsSettingsModalController from "views/assignments/AssignmentsHomePage/AssignmentsSettingsModalController";
import AssignmentsAllocationsModalController from "views/assignments/AssignmentsHomePage/AssignmentsAllocationsModalController";

const formKey = "ASSIGNMENT_SEARCH";
const formKeySaved = "ASSIGNMENT_SEARCH__SAVED";
const assignmentsKey = "ASSIGNMENT_FORM";

class AssignmentsHomeController extends Controller {
  static contextTypes = {
    displayModal: PropTypes.func.isRequired,
    showToast: PropTypes.func,
    showSuccess: PropTypes.func,
    showError: PropTypes.func,
  };

  mainComponent = AssignmentsHomePage;

  title = () => "Assignments | Coachland";

  fetch = () => {
    FormStore.insertIfEmpty(formKey, new CDUSearchFilter());
    FormStore.insertIfEmpty(formKeySaved, new CDUSearchFilter());
    FormStore.insert(assignmentsKey, {});
    this.fetchAssignments();
  };

  fetchAssignments = () => {
    AssignmentsCollection.fetchWithFilter({
      filter: FormStore.get(formKeySaved),
    });

    this.autorun(assignmentsKey, () => {
      if (this.getData().assignments && this.getData().assignments.length > 0) {
        const formData = this.getData().assignments.reduce(
          (data, item) => Object.assign(data, { [item.cduAccessCode]: item }),
          {}
        );
        FormStore.insert(assignmentsKey, formData);

        return true;
      }
    });
  };

  subscribe = (onData) => {
    AssignmentsCollection.addChangeListener(onData);
    ApiRequestsCollection.addChangeListener(onData);
    FormStore.addListener(formKey, onData);
    FormStore.addListener(formKeySaved, onData);
    FormStore.addListener(assignmentsKey, onData);
  };

  unsubscribe = (onData) => {
    AssignmentsCollection.removeChangeListener(onData);
    ApiRequestsCollection.removeChangeListener(onData);
    FormStore.removeListener(formKey, onData);
    FormStore.removeListener(formKeySaved, onData);
    FormStore.removeListener(assignmentsKey, onData);
  };

  onFilterNow = () => {
    FormStore.update(formKeySaved, FormStore.get(formKey));
    this.fetchAssignments();
  };

  onFilterDelayed = _.debounce(this.onFilterNow, 750);

  @handler
  onPageChange = (page) => () => {
    FormStore.update(formKey, { page });
    this.onFilterDelayed();
  };

  @handler
  onBoolFilterChange = (key) => () => {
    FormStore.update(formKey, {
      [key]: !_.get(FormStore.get(formKey), key),
      page: 1,
    });
    this.onFilterDelayed();
  };

  @handler
  onTextFilterChange = (key) => (evt) => {
    FormStore.update(formKey, {
      [key]: evt.currentTarget.value,
      page: 1,
    });
    this.onFilterDelayed();
  };

  @handler
  onValueFilterChange = (key) => (value) => {
    FormStore.update(formKey, {
      [key]: value,
      page: 1,
    });
    this.onFilterDelayed();
  };

  @handler
  onSort = (key) => {
    FormStore.update(formKey, {
      sortKey: key,
      page: 1,
    });
    this.onFilterDelayed();
  };

  onSetItem = (cduAccessCode, param) => {
    const data = _(Assignment.editableProperties)
      .map((prop) => {
        var result = FormStore.get(assignmentsKey)?.[cduAccessCode]?.[prop];
        if (result == "null" || result == "NULL") {
          result = null;
        }
        return [prop, result];
      })
      .fromPairs()
      .value();
    AssignmentsCollection.update({
      cduAccessCode,
      data,
      key: param,
    }).catch((error) => {
      console.log("AssignmentsHomeController ERROR:", error);
      this.context.showError(error.toString());
    });
  };

  onSetItemDelayed = _.debounce(this.onSetItem, 750);

  @handler
  onBoolItemChange = (cduAccessCode, param) => (evt) => {
    this.onItemChange(
      cduAccessCode,
      param,
      !FormStore.get(assignmentsKey)?.[cduAccessCode]?.[param]
    );
  };

  @handler
  onNumberItemChange = (cduAccessCode, param) => (evt) => {
    this.onItemChange(cduAccessCode, param, Number(evt.currentTarget.value));
  };

  @handler
  onTextItemChange = (cduAccessCode, param) => (evt) => {
    this.onItemChange(cduAccessCode, param, evt.currentTarget.value);
  };

  onItemChange = (cduAccessCode, param, value) => {
    FormStore.update(assignmentsKey, {
      [cduAccessCode]: Object.assign(
        {},
        FormStore.get(assignmentsKey)?.[cduAccessCode],
        { [param]: value }
      ),
    });
    this.onSetItemDelayed(cduAccessCode, param);
  };

  @handler
  onDisplaySettings = () => {
    this.context.displayModal(<AssignmentsSettingsModalController />, {
      dismissable: true,
    });
  };

  @handler
  onDisplayAllocations = (cduAccessCode) => () => {
    this.context.displayModal(
      <AssignmentsAllocationsModalController cduAccessCode={cduAccessCode} />,
      { dismissable: true }
    );
  };

  getData = () => {
    const savedFilter = FormStore.get(formKeySaved);
    return {
      assignments: AssignmentsCollection.getWithFilter({
        filter: savedFilter,
      }),
      filter: FormStore.get(formKey),
      formData: FormStore.get(assignmentsKey),
      savedFilter: savedFilter,
      searchRequest: ApiRequestsCollection.get("assignments.search"),
      updateRequests:
        ApiRequestsCollection.getSubsetWithPrefix("assignments.update"),
    };
  };

  isReady = () => {
    return this.state.assignments;
  };
}

export default AssignmentsHomeController;
