/**
 * Controller for the coverage page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import { Controller } from "@noom/noomscape";
import CoverageHomePage from "views/coverage/CoverageHomePage/CoverageHomePage";


class CoverageHomeController extends Controller {
  mainComponent = CoverageHomePage;

  title = () => "Noom Noom";

  fetch = () => {
  };

  getData = () => {
    return {

    };
  };

  isReady = () => {
    return true;
  };
}

export default CoverageHomeController;
