/**
 * Controller for the user profile page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import { Controller } from "@noom/noomscape";
import UserProfilePage from "views/user/UserProfilePage/UserProfilePage";

import UserProfileSingleton from "model/collections/UserProfileSingleton";


class UserProfileController extends Controller {

  mainComponent = UserProfilePage;

  title = () => "Noom - Settings";

  fetch = () => {
  };

  getData = () => {
    return {
      profile: UserProfileSingleton.get(),
    };
  };

  isReady = () => {
    return !!this.state.profile;
  };
}

export default UserProfileController;
