/**
 * Model for CDU employment
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author Hubert
*/


import _ from "lodash";
import { enu } from "@noom/noomscape";


class Employment {

  static languages = ["en", "es", "de", "ko", "ja"];
  static rampUpType = enu(["NONE", "FOUR_WEEKS_WEEKLY_LINEAR"]);

  static rampUpTypeForRampUp = (inRampUp) => (
    inRampUp ? Employment.rampUpType.FOUR_WEEKS_WEEKLY_LINEAR : Employment.rampUpType.NONE
  );

  static properties = [
    "hasFixedSchedule",
    "rampUpType",
    "dateStarted",
    "dateEnded",
    "language",
  ];

  constructor(raw) {
    _.assign(this, _.pick(raw, Employment.properties));
  }

  isInRampUp() {
    return this.rampUpType && this.rampUpType !== Employment.rampUpType.NONE;
  }

}

export default Employment;
