/**
 * Sheet cell with custom component
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import React from "react";
import { If, instance } from "@noom/noomscape";


const SheetCustomField = ({
  label,
  children,
  hasValue,
}) => (
  <div className="landlookSheet-field">
    <div className="landlookSheet-label">
      <If
        if={ hasValue === false }
        then={ () => (
          <div className="landlookSheet-indicator">
            <div className="landlookSheet-indicatorInner"/>
          </div>
        ) }
      />
      { label }
    </div>
    <If
      if={ hasValue !== false }
      then={ () => (
        <div className="landlookSheet-value">
          { instance(children) }
        </div>
      ) }
      else={ () => (
        <div className="landlookSheet-noValue">
          N/A
        </div>
      ) }
    />
  </div>
);


export default SheetCustomField;
