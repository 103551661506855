/**
 * Endpoint for fetching one UFC by accessCode
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import axios from "axios";
import { getAuthorizationHeader } from "@noom/hive-auth";
import { Api } from "@noom/noomscape";

Api.register("ufc.get", ({ ufcAccessCode }) => {
  return axios({
    url: `/coaches/ufcs/${ufcAccessCode}`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
});
