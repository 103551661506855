import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("user.getProfile", async ({ userAccessCode, full = true }) => {
  const response = await axios({
    url: `/users/${userAccessCode}/basic_profile`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
    params: {
      full,
    },
  });

  return response?.data;
});
