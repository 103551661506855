/**
 * Bulk assignment tool form
 *
 * Copyright (C) 2020C Noom, Inc.
 * @author nikola
 */

import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";

import {
  Box,
  Input,
  Textarea,
  Button,
  Grid,
  Spacing,
  RadioGroup,
  VariantColor,
} from "@noom/wax-component-library";

import "./BulkAssignmentLayout.scss";

function parseValues(values) {
  const { assignmentStatus, maxCaseload, maxNewCoacheesPerDay, ...rest } =
    values;
  const parsedValues = { ...rest };

  parsedValues.isInRotation = { on: true, off: false }[assignmentStatus];

  parsedValues.maxCaseload = Number.isInteger(maxCaseload)
    ? maxCaseload
    : undefined;
  parsedValues.maxNewCoacheesPerDay = Number.isInteger(maxNewCoacheesPerDay)
    ? maxNewCoacheesPerDay
    : undefined;

  parsedValues.targetCdus = values.targetCdus
    .replace(/\r?\n/g, " ")
    .trim()
    .split(" ")
    .filter(Boolean);

  return parsedValues;
}

function isValidAccessCode(accessCode = "") {
  return /^[A-Z0-9]{8}$/.test(accessCode);
}

function validate(values) {
  const errors = {};
  const parsedValues = parseValues(values);

  if (parsedValues.targetCdus.length === 0) {
    errors.targetCdus = "CDU access code list is required!";
  } else {
    const invalidUfc = parsedValues.targetCdus.find(
      (ufc) => !isValidAccessCode(ufc)
    );
    if (invalidUfc) {
      errors.targetCdus = `${invalidUfc} is not a valid access code!`;
    }
  }

  return errors;
}

const BulkAssignmentForm = (props) => {
  const { onSubmit, defaultValues } = props;

  const formik = useFormik({
    validate,
    initialValues: {
      assignmentStatus: defaultValues.assignmentStatus ?? "no-change",
      maxCaseload: defaultValues.maxCaseload ?? "",
      maxNewCoacheesPerDay: defaultValues.maxNewCoacheesPerDay ?? "",
      targetCdus: defaultValues.targetCdus?.join(" ") ?? "",
    },
    onSubmit: (values) => onSubmit(parseValues(values)),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Grid columns={3} spacing={Spacing[2]}>
          <Box>
            <RadioGroup
              name="assignmentStatus"
              label="Assignment ON/OFF"
              isInline
              size="md"
              options={[
                { label: "ON", value: "on" },
                { label: "OFF", value: "off" },
                { label: "NO CHANGE", value: "no-change" },
              ]}
              onChange={(value) =>
                formik.setFieldValue("assignmentStatus", value)
              }
              onBlur={formik.handleBlur}
              value={formik.values.assignmentStatus}
              error={
                formik.touched.assignmentStatus &&
                formik.errors.assignmentStatus
              }
              variantColor={VariantColor.secondary}
            />
          </Box>
          <Grid columns={2}>
            <Input
              label="Max caseload"
              name="maxCaseload"
              type="number"
              min="0"
              max="999"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.maxCaseload}
              error={formik.touched.maxCaseload && formik.errors.maxCaseload}
              variantColor={VariantColor.secondary}
            />

            <Input
              label="Max new users"
              name="maxNewCoacheesPerDay"
              type="number"
              min="0"
              max="999"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.maxNewCoacheesPerDay}
              error={
                formik.touched.maxNewCoacheesPerDay &&
                formik.errors.maxNewCoacheesPerDay
              }
              variantColor={VariantColor.secondary}
            />
          </Grid>
        </Grid>
      </Box>

      <div className="landlookSheet-field">
        <div className="landlookSheet-label">
          <b>List of coach (CDU) access code to update</b>
        </div>
        <div className="landlookSheet-label">
          <b>Format:</b>"First CDU access code" "Second CDU access code"...
          Without quotes!
        </div>

        <div className="landlookSheet-label">
          <b>Example:</b> <i>1ABEHANC CSH43OT3 FRFIRST2 RULENO34</i>
        </div>

        <Textarea
          autoFocus
          name="targetCdus"
          className="textarea"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.targetCdus}
          error={formik.touched.targetCdus && formik.errors.targetCdus}
          variantColor={VariantColor.secondary}
        />
      </div>

      <div className="bulkAssignment-buttonWrap">
        <Button type="submit" variantColor={VariantColor.secondary}>
          Update
        </Button>
      </div>
    </form>
  );
};

BulkAssignmentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};

export default BulkAssignmentForm;
