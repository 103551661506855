/**
 * Collection for assignment rotations
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author Hubert
 */

import { Collection, DevTools, Api } from "@noom/noomscape";

class SettingsCollection extends Collection {
  fetchAssignmentsSettings = () => {
    if (this.items["assignments"]) {
      return;
    }

    Api.call("settings.assignments.fetch", Api.api.settingsFetchAssignments, {})
      .then((data) => {
        this.items["assignments"] = data;
        this.emitChange();
      })
      .catch((error) => {
        console.log("ASSIGNMENT SETTINGS ERROR", error);
      });
  };

  /*
    ============================================================
    Getters
  */

  getAssignmentsSettings = () => {
    return this.items["assignments"];
  };

  /*
    ============================================================
    Updaters
  */

  updateAssignmentsSettings = ({ isAutoModeOn }) => {
    return Api.call(
      "settings.assignments.isAutoModeOn",
      Api.api.settingsUpdateAssignments,
      { isAutoModeOn }
    )
      .then((raw) => {
        delete this.items["assignments"];
        this.fetchAssignmentsSettings();
        this.emitChange();
      })
      .catch((err) => {
        throw err;
      });
  };
}

const instance = new SettingsCollection();
DevTools.expose({ SettingsCollection: instance });
export default instance;
