/**
 * Upper part of the form, editable for brand new coaches
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import React from "react";
import PropTypes from "prop-types";
import SheetBoolField from "components/sheet/SheetBoolField";
import SheetDateField from "components/sheet/SheetDateField";
import SheetTextField from "components/sheet/SheetTextField";
import CDU from "model/items/CDU";
import Tableau from "model/items/Tableau";


const GrayForm = ({
  cdu,
  formData,

  onInputChange,
  onValueChange,
}) => (
  <React.Fragment>
    <div className="landlookGrid-row">
      <div className="landlookGrid-column4">
        <SheetTextField
          label="Noom email"
          value={ formData.email }
          editMode={ false }
          onChange={ onInputChange("email") }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetTextField
          label="CDU access code"
          value={ cdu.accessCode }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetTextField
          label="Manager full name"
          value={ cdu.managerName }
        />
      </div>
    </div>


    <div className="landlookGrid-row">
      <div className="landlookGrid-column4">
        <SheetTextField
          label="Namely Employee ID"
          value={ formData.namelyEmployeeId }
          editMode={ true }
          onChange={ onInputChange("namelyEmployeeId") }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetDateField
          label="CDU start date"
          value={ formData.employmentStartDate }
          editMode={ true }
          onChange={ onInputChange("employmentStartDate") }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetTextField
          label="Manager CDU access code"
          value={ formData.managerAccessCode }
          editMode={ true }
          onChange={ onInputChange("managerAccessCode") }
        />
      </div>
    </div>


    <div className="landlookGrid-row">
      <div className="landlookGrid-column4 landlookGrid-columnRightBordered">
        <SheetTextField
          label="Hours"
          value={ formData.schedule?.weeklyHours }
          type="number"
          editMode={ true }
          onChange={ onInputChange("schedule.weeklyHours") }
        />
      </div>
      <div className="landlookGrid-column4 landlookGrid-columnRightBordered">
        <SheetTextField
          label="Coach role"
          value={ Tableau.roleToDisplayName(cdu.role) }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetBoolField
          label="Still in the org"
          value={ formData.isActive }
          editMode={ true }
          onChange={ onValueChange("isActive") }
        />
      </div>
    </div>
  </React.Fragment>
);


GrayForm.propTypes = {
  cdu: PropTypes.instanceOf(CDU).isRequired,
  formData: PropTypes.object.isRequired,
};

export default GrayForm;
