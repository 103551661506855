/**
 * Endpoint for fetching active CDU employment
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";
import Employment from "model/items/Employment";

Api.register("cduDataEmployment", ({ cduAccessCode }) => {
  return axios({
    url: `/coaches/cdus/${cduAccessCode}/employment`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((response) => {
    return _.pick(response.data, Employment.properties);
  });
});
