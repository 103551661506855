/**
 * Controller for the bulk assignment tool layout.
 *
 * Copyright (C) 2020C Noom, Inc.
 * @author nikola
 */

import moment from "moment";
import { Api, Controller, handler } from "@noom/noomscape";

import UserProfileSingleton from "model/collections/UserProfileSingleton";

import BulkAssignmentLayout from "./BulkAssignmentLayout";

const getDefaultState = () => ({
  log: [],
  failed: [],
  inProgress: false,
  showReport: false,
  showConfirmationModal: false,
  values: {
    targetCdus: [],
  },
});

class BulkAssignmentController extends Controller {
  mainComponent = BulkAssignmentLayout;

  constructor(props) {
    super(props);
    this.state = getDefaultState();
  }

  subscribe = (onData) => {
    UserProfileSingleton.addChangeListener(onData);
  };

  unsubscribe = (onData) => {
    UserProfileSingleton.removeChangeListener(onData);
  };

  validateCDU = async (cduAccessCode) => {
    try {
      const data = await Api.call("cduOne", Api.api.cduOne, {
        cduAccessCode,
      });

      await this.log(`CDU(${cduAccessCode}) is a valid coach`);
      return data;
    } catch (err) {
      await this.log(`CDU(${cduAccessCode}) is not a valid coach`, err);
      throw err;
    }
  };

  updateAssignments = async (cduAccessCode, data) => {
    try {
      const currentData = await Api.call(
        "assignmentsOne",
        Api.api.assignmentsOne,
        { cduAccessCode }
      );

      const response = await Api.call(
        "assignmentsUpdate",
        Api.api.assignmentsUpdate,
        {
          cduAccessCode,
          data: { ...currentData, ...data },
        }
      );

      await this.log(`CDU(${cduAccessCode}) updated assignment`);
      return response.data;
    } catch (err) {
      await this.log(`CDU(${cduAccessCode}) unable to update assignment`, err);
      throw err;
    }
  };

  @handler
  onStart = async () => {
    await this.setState({
      showConfirmationModal: false,
      inProgress: true,
      showReport: false,
    });
    const { isInRotation, maxCaseload, maxNewCoacheesPerDay, targetCdus } =
      this.state?.values;

    const successCDUs = [];
    const failCDUs = [];

    for (const targetCdu of targetCdus) {
      try {
        const cdu = await this.validateCDU(targetCdu);

        const assignmentData = {
          isInRotation,
          maxCaseload,
          maxNewCoacheesPerDay,
        };

        // Remove undefined data
        Object.keys(assignmentData).forEach((key) => {
          if (assignmentData[key] === undefined) {
            delete assignmentData[key];
          }
        });

        if (Object.keys(assignmentData).length) {
          await this.updateAssignments(targetCdu, assignmentData);
        }

        successCDUs.push(targetCdu);
      } catch (e) {
        failCDUs.push(targetCdu);
      }
    }

    await this.log("DONE!");
    await this.log("------------------------------------------");
    await this.log("REPORT:");
    await this.log(
      `CDUs updated successfully: ${successCDUs.length}/${
        successCDUs.length + failCDUs.length
      }`,
      failCDUs.length > 0
    );

    if (failCDUs.length) {
      await this.log(`Failed CDUs: ${failCDUs.join(" ")}`);
    }

    await this.setState({ inProgress: false, showReport: true });
  };

  @handler
  onCancel = async () => {
    await this.setState({ showConfirmationModal: false });
  };

  @handler
  onSubmit = async (values) => {
    await this.clearLog();
    await this.setState({
      showConfirmationModal: true,
      values,
    });
  };

  @handler
  onRetryFailed = async () => {
    await this.setState({ inProgress: false, showReport: false, log: [] });
  };

  @handler
  onRetryAll = async () => {
    const { values } = this.state;
    await this.setState({ ...getDefaultState(), values });
  };

  @handler
  onReset = async () => {
    await this.setState(getDefaultState());
  };

  getData = () => {
    return {
      log: this.state.log,
      failed: this.state.failed,
      showReport: this.state.showReport,
      inProgress: this.state.inProgress,
      user: UserProfileSingleton.get(),
      values: this.state.values,
      showConfirmationModal: this.state.showConfirmationModal,
    };
  };

  log = async (value, error) => {
    await this.setState({ log: [...this.state.log, { value, error }] });
  };

  clearLog = async () => {
    await this.setState({ log: [] });
  };

  isReady = () => {
    return true;
  };
}

export default BulkAssignmentController;
