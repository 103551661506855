/**
 * Off-boarding form
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import React from "react";
import { useFormik } from "formik";

import {
  Input,
  Textarea,
  Button,
  VariantColor,
  Switch,
  Collapse,
} from "@noom/wax-component-library";

import { isValidAccessCode, parseAccessCodeList } from "modules/tools";

import "./OffboardingLayout.scss";

export type FormValue = {
  sourceUfc: string;
  targetUfcs: string;
  useTargetUsers: boolean;
  targetUsers: string;
};

export type FormError = Partial<FormValue>;

export type OutputValue = {
  sourceUfc: string;
  targetUfcs: string[];
  useTargetUsers: boolean;
  targetUsers: string[];
};

export type OffboardingFormProps = {
  onSubmit: (value: OutputValue) => void;
  defaultValues?: OutputValue;
};

function parseValues(values: FormValue): OutputValue {
  return {
    sourceUfc: values.sourceUfc.trim(),
    targetUfcs: parseAccessCodeList(values.targetUfcs),
    useTargetUsers: values.useTargetUsers,
    targetUsers: parseAccessCodeList(values.targetUsers),
  };
}

function validate(values: FormValue) {
  const errors: FormError = {};
  const parsedValues = parseValues(values);

  if (!parsedValues.sourceUfc) {
    errors.sourceUfc = "UFC access code is required!";
  } else if (!isValidAccessCode(parsedValues.sourceUfc)) {
    errors.sourceUfc = `${parsedValues.sourceUfc} is not a valid access code!`;
  }

  if (parsedValues.targetUfcs.length === 0) {
    errors.targetUfcs = "UFC access code list is required!";
  } else {
    const invalidUfc = parsedValues.targetUfcs.find(
      (ufc) => !isValidAccessCode(ufc)
    );
    if (invalidUfc) {
      errors.targetUfcs = `${invalidUfc} is not a valid access code!`;
    }
  }

  if (parsedValues.useTargetUsers && parsedValues.targetUsers.length === 0) {
    errors.targetUsers = "User access code (UAC) list is required!";
  } else if (
    parsedValues.useTargetUsers &&
    parsedValues.targetUsers.length > 0
  ) {
    const invalidUser = parsedValues.targetUsers.find(
      (uac) => !isValidAccessCode(uac)
    );
    if (invalidUser) {
      errors.targetUsers = `${invalidUser} is not a valid access code!`;
    }
  }

  return errors;
}

const OffboardingForm = (props: OffboardingFormProps) => {
  const { onSubmit, defaultValues } = props;

  const formik = useFormik<FormValue>({
    validate,
    initialValues: {
      sourceUfc: defaultValues?.sourceUfc ?? "",
      targetUfcs: defaultValues?.targetUfcs?.join(" ") ?? "",
      useTargetUsers: defaultValues?.useTargetUsers ?? false,
      targetUsers: defaultValues?.targetUsers?.join(" ") ?? "",
    },
    onSubmit: (values) => onSubmit(parseValues(values)),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="landlookSheet-field">
        <div className="landlookSheet-label">
          <b>Coach (UFC) access code to be offboarded</b>
        </div>

        <div className="landlookSheet-label">
          <b>Example:</b> <i>2CHEWIE4</i>
        </div>

        <Input
          name="sourceUfc"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sourceUfc}
          error={formik.touched.sourceUfc && formik.errors.sourceUfc}
        />
      </div>

      <div className="landlookSheet-field">
        <div className="landlookSheet-label">
          <b>List of coach (UFC) access code to recieve the users</b>
        </div>
        <div className="landlookSheet-label">
          <b>Format:</b>"First UFC access code" "Second UFC access code"...
          Without quotes!
        </div>

        <div className="landlookSheet-label">
          <b>Example:</b> <i>1ABEHANC CSH43OT3 FRFIRST2 RULENO34</i>
        </div>

        <Textarea
          name="targetUfcs"
          className="textarea"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.targetUfcs}
          error={formik.touched.targetUfcs && formik.errors.targetUfcs}
        />
      </div>

      <div className="landlookSheet-field">
        <div className="landlookSheet-label">
          <b>Use a pre-set list of users (UAC) to move: </b>

          <Switch
            size="sm"
            name="useTargetUsers"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isChecked={formik.values.useTargetUsers}
          />
        </div>
        <Collapse isOpen={formik.values.useTargetUsers}>
          <div className="landlookSheet-label">
            <b>Format:</b>"First user access code" "Second user access code"...
            Without quotes!
          </div>

          <div className="landlookSheet-label">
            <b>Example:</b> <i>1ATHISNC CSHISOT3 FRFMYST2 RULEUSER</i>
          </div>

          <Textarea
            name="targetUsers"
            className="textarea"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.targetUsers}
            error={formik.touched.targetUsers && formik.errors.targetUsers}
          />
        </Collapse>
      </div>

      <div className="offboardingLayout-buttonWrap">
        <Button type="submit" colorScheme={VariantColor.secondary}>
          Offboard
        </Button>
      </div>
    </form>
  );
};

export default OffboardingForm;
