/**
 * Endpoint for adding users to groups
 *
 * Copyright (C) 2019D Noom, Inc.
 * @author nikola
 */

import axios from "axios";
import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register(
  "group.addUser",
  async ({ isLeader = false, groupId, accessCode }) => {
    return axios({
      url: `/users/${accessCode}/joinGroup/${groupId}`,
      baseURL: process.env.HIVE_SERVER_URL,
      method: "GET",
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      params: {
        intro: false,
        memberType: isLeader ? "LEADER" : "MEMBER",
        notify: false,
        deleteOthers: !isLeader,
      },
    });
  }
);
