/**
 * View for the assignment page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { List, AutoSizer } from "react-virtualized";

import Assignment from "model/items/Assignment";
import CDUSearchFilter from "model/utils/CDUSearchFilter";
import { Icon, If, ApiRequest } from "@noom/noomscape";
import CDUFilterBox from "components/CDUFilterBox/CDUFilterBox";
import AssignmentsListItem from "views/assignments/AssignmentsHomePage/AssignmentsListItem";
import SortingHeader from "components/etc/SortingHeader";

const ROW_HEIGHT = 58;
const COACHADMIN_URL = process.env.HIVE_COACHADMIN_URL || '';

const AssignmentsHomePage = ({
  assignments,
  filter,
  savedFilter,
  formData,
  searchRequest,
  updateRequests,

  onSort,

  onBoolFilterChange,
  onTextFilterChange,
  onValueFilterChange,
  onBoolItemChange,
  onNumberItemChange,
  onTextItemChange,
  onPageChange,
  onDisplaySettings,
  onDisplayAllocations,
}) => (
  <div className="landlookLayout-fixedPage">
    <div className="landlookLayout-fixedPageWrap">
      <div className="landlookHorizontalForm-box">
        <div className="landlookHorizontalForm-part">
          <div className="landlookPages-box">
            {_.range(1, 11).map((idx) => (
              <div
                key={idx}
                className={`landlookPages-page ${
                  filter.page === idx ? "active" : ""
                }`}
                onClick={onPageChange(idx)}
              >
                {idx}
              </div>
            ))}
          </div>
        </div>
        <div className="landlookHorizontalForm-space" />
        <CDUFilterBox
          options={{
            inRotation: true,
            inExperiment: true,
            localeFilter: "en_US",
            packageFilter: "HEALTHY_WEIGHT",
            coursePackFilter: "ALL",
            businessType: "ALL",
          }}
          filter={filter}
          onBoolFilterChange={onBoolFilterChange}
          onTextFilterChange={onTextFilterChange}
          onValueFilterChange={onValueFilterChange}
          searchRequest={searchRequest}
        />
        <div className="landlookHorizontalForm-part">
          <div className="landlookIconButton" onClick={onDisplaySettings}>
            <Icon icon="gear" />
          </div>
        </div>
      </div>

      <div className="landlookList-box">
        <div className="landlookList-header landlookListHeader-box">
          <SortingHeader
            className="landlookListHeader-column landlookListColumn-3"
            sortUpKey="NAME_ASC"
            sortDownKey="NAME_DESC"
            currentKey={filter.sortKey}
            onSort={onSort}
          >
            CDU
          </SortingHeader>
          <div className="landlookListHeader-column landlookListColumn-2">
            Acccess code
          </div>
          <div className="landlookListHeader-column landlookListColumn-4">
            Email
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Locale
          </div>
          <div className="landlookListHeader-column landlookListColumn-3">
            Package
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Course Pack
          </div>

          <SortingHeader
            className="landlookListHeader-column landlookListColumn-2"
            sortUpKey="CASELOAD_ASC"
            sortDownKey="CASELOAD_DESC"
            currentKey={filter.sortKey}
            onSort={onSort}
          >
            Caseload
          </SortingHeader>
          <div className="landlookListHeader-column landlookListColumn-1">
            GS FTEs
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Allocations
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Business Type
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Assign to
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Max caseload
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Max new users
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Coach Type
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Assign To UFC Access Code
          </div>
        </div>
        <If
          if={
            assignments.length > 0 ||
            (searchRequest && searchRequest.state === ApiRequest.state.LOADING)
          }
          then={() => (
            <div className="landlookList-content">
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    height={height}
                    width={width}
                    rowHeight={ROW_HEIGHT}
                    rowCount={assignments.length}
                    rowRenderer={({ index, isScrolling, key, style }) => (
                      <AssignmentsListItem
                        item={assignments[index]}
                        formData={formData}
                        updateRequests={updateRequests}
                        index={index}
                        isScrolling={isScrolling}
                        key={key}
                        style={style}
                        highlight={savedFilter.query}
                        onBoolChange={onBoolItemChange}
                        onNumberChange={onNumberItemChange}
                        onTextChange={onTextItemChange}
                        onDisplayAllocations={onDisplayAllocations}
                        redirectToCoachAdminUrl={`${COACHADMIN_URL}/ufc/${assignments[index].assignToUfcAccessCode}`}
                      />
                    )}
                  />
                )}
              </AutoSizer>
            </div>
          )}
          else={() => (
            <div className="landlookList-emptyContent">
              <img
                src="/assets/img/bang.svg"
                className="landlookList-emptyImage"
              />
              <div className="landlookList-emptyNote">0 results were found</div>
            </div>
          )}
        />
      </div>
    </div>
  </div>
);

AssignmentsHomePage.propTypes = {
  assignments: PropTypes.arrayOf(PropTypes.instanceOf(Assignment)).isRequired,
  filter: PropTypes.instanceOf(CDUSearchFilter).isRequired,
  savedFilter: PropTypes.instanceOf(CDUSearchFilter).isRequired,
  searchRequest: PropTypes.instanceOf(ApiRequest),
  onBoolFilterChange: PropTypes.func.isRequired,
  onTextFilterChange: PropTypes.func.isRequired,
  onValueFilterChange: PropTypes.func.isRequired,
  onBoolItemChange: PropTypes.func.isRequired,
  onNumberItemChange: PropTypes.func.isRequired,
  onTextItemChange: PropTypes.func.isRequired,
  onDisplaySettings: PropTypes.func.isRequired,
  onDisplayAllocations: PropTypes.func.isRequired,
};

export default AssignmentsHomePage;
