/**
 * Tableau display for the lower part of the data page
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";
import React from "react";
import { If } from "@noom/noomscape";
import Tableau from "model/items/Tableau";


const WhitePartTableau = ({
  tableau,
  baseline,
}) => (
  <div className={ `whitePart-tableau ${tableau.active ? "whitePart-tableauActive" : ""}` }>
    <div className="whitePart-tableauHeader">
      <div className="whitePart-tableauTitle">
        { baseline ? "Baseline Allocation" : "Temporary" }
      </div>
      <If
        if={ tableau.active }
        then={ () => (
          <div className="whitePart-tableauMark">
            ACTIVE
          </div>
        ) }
      />
    </div>

    { _.map(tableau.responsibilityAllocations, ra => (
      <If
        key={ ra.responsibility }
        if={ Tableau.typeInfo[ra.responsibility].coaching }
        then={ () => (
          <div className="whitePart-line">
            <div className="whitePart-lineLabel">
              Coaching as
            </div>
            <div className="whitePart-lineContent">
              { Tableau.typeInfo[ra.responsibility].label }
              &nbsp; &mdash; &nbsp;
              { ra.percent }%
            </div>
          </div>
        ) }
        else={ () => (
          <div className="whitePart-line">
            <div className="whitePart-lineLabel">
              { Tableau.typeInfo[ra.responsibility].label }
            </div>
            <div className="whitePart-lineContent">
              { ra.percent }%
            </div>
          </div>
        ) }
      />
    )) }

    <If
      if={ !baseline }
      then={ () => (
        <div className="whitePart-line">
          <div className="whitePart-lineLabel">
            Start date
          </div>
          <div className="whitePart-lineContent">
            { tableau.dateStarted }
          </div>
        </div>
      ) }
    />

    <If
      if={ !baseline }
      then={ () => (
        <div className="whitePart-line">
          <div className="whitePart-lineLabel">
            End date
          </div>
          <div className="whitePart-lineContent">
            { tableau.dateEnded }
          </div>
        </div>
      ) }
    />

  </div>
);


export default WhitePartTableau;
