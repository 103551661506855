/**
 * Endpoint for fetching assignment data for one CDU
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("settingsUpdateAssignments", ({ isAutoModeOn }) => {
  return axios({
    url: "/assignmentRotations/autoAssignSwitch",
    baseURL: process.env.HIVE_SERVER_URL,
    method: "PUT",
    data: "" + isAutoModeOn,
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => "ok")
    .catch((error) => {
      console.log("UPDATE ASSIGNMENT SETTINGS ERROR", error);
      throw error;
    });
});
