/**
 * Endpoint for current user profile
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";
import UserProfile from "model/items/UserProfile";

Api.register("userProfile", ({ accessCode }) => {
  return axios({
    url: `/coaches/cdus/${accessCode}`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((response) => {
    return _.pick(response.data, UserProfile.properties);
  });
});
