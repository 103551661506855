/**
 * Controller for the vacation page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import { Controller } from "@noom/noomscape";
import VacationHomePage from "views/vacation/VacationHomePage/VacationHomePage";


class VacationHomeController extends Controller {
  mainComponent = VacationHomePage;

  title = () => "Noom Noom";

  fetch = () => {
  };

  getData = () => {
    return {

    };
  };

  isReady = () => {
    return true;
  };
}

export default VacationHomeController;
