/**
 * Sheet cell with text data
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import React from "react";
import { If } from "@noom/noomscape";


const SheetTextField = ({
  label,
  value,
  type,
  editMode,
  onChange,
}) => (
  <div className="landlookSheet-field">
    <div className="landlookSheet-label">
      <If
        if={ (value === null || value === undefined) }
        then={ () => (
          <div className="landlookSheet-indicator">
            <div className="landlookSheet-indicatorInner"/>
          </div>
        ) }
      />
      { label }
    </div>
    <div className={ (value === null || value === undefined) ? "landlookSheet-noValue" : "landlookSheet-value" }>
      <If
        if={ editMode }
        then={ () => (
          <React.Fragment>
            <input
              className="landlookSheet-input"
              type={ type || "text" }
              value={ value || "" }
              onChange={ onChange }
            />
          </React.Fragment>
        ) }
        else={ () => (
          <React.Fragment>
            { (value === null || value === undefined) ? "N/A" : value }
          </React.Fragment>
        ) }
      />

    </div>
  </div>
);


export default SheetTextField;
