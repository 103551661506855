/**
 * View for the vacation page.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
*/


import React from "react";


class VacationHomePage extends React.Component {
  static propTypes = {

  };

  render = () => (
    <div className="landlookLayout-fixedPage">
      <div className="landlookLayout-fixedPageWrap">
        <div className="landlookLayout-tmp">
          <div className="landlookLayout-tmpTitle">Vacation!</div>
          <img
            className="landlookLayout-tmpImage"
            src="/assets/img/notdoneyet.png"
          /><br/>
        </div>
      </div>
    </div>
  );

}

export default VacationHomePage;
