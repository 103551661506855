import PropTypes from "prop-types";
import { Controller, handler } from "@noom/noomscape";
import moment from "moment";

import AssignmentsAllocationsModal from "views/assignments/AssignmentsHomePage/AssignmentsAllocationsModal";

import CDUCollection from "model/collections/CDUCollection";

class AssignmentsAllocationsModalController extends Controller {
  static propTypes = {
    cduAccessCode: PropTypes.string,
  }

  static contextTypes = {
    popModal: PropTypes.func.isRequired,
    showToast: PropTypes.func,
    showSuccess: PropTypes.func,
    showError: PropTypes.func,
  };

  mainComponent = AssignmentsAllocationsModal;

  fetch = () => {
    CDUCollection.fetchOne(this.props.cduAccessCode, {complete: true});
  }

  subscribe = (onData) => {
    CDUCollection.addChangeListener(onData);
  };

  unsubscribe = (onData) => {
    CDUCollection.removeChangeListener(onData);
  };

  @handler
  onDismiss = () => {
    // TODO: Replace context api when moving to component library
    this.context.popModal();
  };

  getData = () => {
    return {
      cdu: CDUCollection.get(this.props.cduAccessCode)
    };
  };

  isReady = () => {
    return (this.state.cdu);
  };
}


export default AssignmentsAllocationsModalController;
