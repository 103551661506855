/**
 * Endpoint for updating assignment data
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("cduDataCreateSchedule", ({ cduAccessCode, schedule }) => {
  return axios({
    url: `/coaches/cdus/${cduAccessCode}/schedules/active`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "PUT",
    data: schedule,
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return "ok";
    })
    .catch((error) => {
      console.log("CREATE SCHEDULE ERROR", error);
      throw error;
    });
});
