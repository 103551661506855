/**
 * View for the main layout.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
 */

import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

import { If } from "@noom/noomscape";
import UserProfile from "model/items/UserProfile";
import BodyClassName from "components/utils/BodyClassName";

import "./MainLayout.scss";

class MainLayout extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserProfile).isRequired,
    onLogout: PropTypes.func.isRequired,
  };

  render = () => (
    <div className="mainLayout-box landlookLayout-universe">
      <BodyClassName className="body-mainLayout" />

      <div className="landlookTopBar-box">
        <div className="landlookLayout-maxWrap landlookLayout-row">
          <div className="landlookTopBar-part">
            <Link
              to="/"
              className="landlookTopBar-logo"
              title={`version: ${window.VERSION || "dev"}`}
            >
              Coach Լand
            </Link>
          </div>
          <div className="landlookTopBar-space" />
          <div className="landlookTopBar-part">
            <NavLink
              to="/cdu"
              className="landlookTopBar-item"
              activeClassName="active"
            >
              Coach Tracker
            </NavLink>
            <If
              if={this.props.user.isAssignmentRotationsManager()}
              then={() => (
                <NavLink
                  to="/assignments"
                  className="landlookTopBar-item"
                  activeClassName="active"
                >
                  Assignments
                </NavLink>
              )}
            />
            <If
              if={this.props.user.isAssignmentRotationsManager()}
              then={() => (
                <div className="landlookTopBar-part">
                  <div className="landlookTopBar-dropdown">
                    <div className="landlookTopBar-dropdownButton landlookTopBar-item">
                      Tools
                    </div>
                    <div className="landlookTopBar-dropdownList">
                      <NavLink
                        to="/group-creation"
                        className="landlookTopBar-dropdownListItem"
                        activeClassName="active"
                      >
                        Group Creation
                      </NavLink>
                      <NavLink
                        to="/user-checker"
                        className="landlookTopBar-dropdownListItem"
                        activeClassName="active"
                      >
                        Group Membership
                      </NavLink>
                      <NavLink
                        to="/group-remover"
                        className="landlookTopBar-dropdownListItem"
                        activeClassName="active"
                      >
                        Group Remover
                      </NavLink>
                      <NavLink
                        to="/offboarding"
                        className="landlookTopBar-dropdownListItem"
                        activeClassName="active"
                      >
                        Offboarding
                      </NavLink>
                      <NavLink
                        to="/bulk-assignment"
                        className="landlookTopBar-dropdownListItem"
                        activeClassName="active"
                      >
                        Bulk Assignment
                      </NavLink>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
          <div className="landlookTopBar-part">
            <div className="landlookTopBar-dropdown">
              <div className="landlookTopBar-dropdownButton">
                {this.props.user.name}
              </div>
              <div className="landlookTopBar-dropdownList">
                <Link to="/user" className="landlookTopBar-dropdownListItem">
                  {this.props.user.accessCode}
                </Link>
                <Link to="/logout" className="landlookTopBar-dropdownListItem">
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landlookLayout-region">{this.props.children}</div>
    </div>
  );
}

export default MainLayout;
