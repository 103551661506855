/**
 * View for the CDU list page.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { List, AutoSizer } from "react-virtualized";

import { ApiRequest, If } from "@noom/noomscape";
import CDU from "model/items/CDU";
import CDUSearchFilter from "model/utils/CDUSearchFilter";
import CDUFilterBox from "components/CDUFilterBox/CDUFilterBox";
import CDUListItem from "views/cdu/CDUListPage/CDUListItem";

const ROW_HEIGHT = 58;

const CDUListPage = ({
  cdus,
  filter,
  savedFilter,
  onBoolFilterChange,
  onTextFilterChange,
  onValueFilterChange,
  searchRequest,
}) => (
  <div className="landlookLayout-fixedPage">
    <div className="landlookLayout-fixedPageWrap">
      <div className="landlookHorizontalForm-box">
        <div className="landlookHorizontalForm-space" />
        <div className="landlookHorizontalForm-part">
          <CDUFilterBox
            options={{
              dates: true,
              activity: true,
              attention: true,
              nmcs: true,
              localeFilter: null,
              packageFilter: null,
            }}
            filter={filter}
            onBoolFilterChange={onBoolFilterChange}
            onTextFilterChange={onTextFilterChange}
            onValueFilterChange={onValueFilterChange}
            searchRequest={searchRequest}
          />
        </div>
      </div>

      <div className="landlookList-box">
        <div className="landlookList-header landlookListHeader-box">
          <div className="landlookListHeader-column landlookListColumn-5">
            Full name
          </div>
          <div className="landlookListHeader-column landlookListColumn-4">
            Email
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            CDU acccess code
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Manager full name
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            CDU start date
          </div>
          <div className="landlookListHeader-column landlookListColumn-2">
            Coach role
          </div>
          <div className="landlookListHeader-column landlookListColumn-1">
            Language
          </div>
        </div>
        <If
          if={
            cdus.length > 0 ||
            (searchRequest && searchRequest.state === ApiRequest.state.LOADING)
          }
          then={() => (
            <div className="landlookList-content">
              <AutoSizer>
                {({ width, height }) => (
                  <List
                    height={height}
                    width={width}
                    rowHeight={ROW_HEIGHT}
                    rowCount={cdus.length}
                    rowRenderer={({ index, isScrolling, key, style }) => (
                      <CDUListItem
                        item={cdus[index]}
                        index={index}
                        isScrolling={isScrolling}
                        key={key}
                        style={style}
                        highlight={savedFilter.query}
                      />
                    )}
                  />
                )}
              </AutoSizer>
            </div>
          )}
          else={() => (
            <If
              if={savedFilter.isEmpty()}
              then={() => (
                <div className="landlookList-emptyContent">
                  <img
                    src="/assets/img/que.svg"
                    className="landlookList-emptyImage"
                  />
                  <div className="landlookList-emptyNote">
                    Please enter a query or select a filter
                  </div>
                </div>
              )}
              else={() => (
                <div className="landlookList-emptyContent">
                  <img
                    src="/assets/img/bang.svg"
                    className="landlookList-emptyImage"
                  />
                  <div className="landlookList-emptyNote">
                    0 results were found
                  </div>
                </div>
              )}
            />
          )}
        />
      </div>
    </div>
  </div>
);

CDUListPage.propTypes = {
  cdus: PropTypes.arrayOf(PropTypes.instanceOf(CDU)).isRequired,
  filter: PropTypes.instanceOf(CDUSearchFilter).isRequired,
  savedFilter: PropTypes.instanceOf(CDUSearchFilter).isRequired,
  searchRequest: PropTypes.instanceOf(ApiRequest),
  onBoolFilterChange: PropTypes.func.isRequired,
  onTextFilterChange: PropTypes.func.isRequired,
};

export default CDUListPage;
