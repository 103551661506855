import React, { useState } from 'react';
import DatePicker from "react-datepicker";

import "./DateRangePicker.scss";

const getFormattedInputDisplay = (startDate, endDate) => {
  const startText = startDate?.toLocaleDateString("en-CA") || "Start Date ";
  const endText = endDate?.toLocaleDateString("en-CA") || " End Date";
  return `${startText} - ${endText}`;
};

const DateRangePicker = ({
  initialStartDate,
  initialEndDate = null,
  onChange = () => {},
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [displayPicker, setDisplayPicker] = useState(false);
  const onDatePickerChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (end || (!start && !end)) {
      onChange(dates);
      setDisplayPicker(false);
    }
  };

  return (
    <div className="DateRangePicker-box">
      <input
        className={`landlookFormField-field DateRangePicker-input ${(startDate || endDate) && "DateRangePicker-populated"}`}
        type="text"
        value={getFormattedInputDisplay(startDate, endDate)}
        onFocus={() => setDisplayPicker(true)}
      />
      {(startDate || endDate) && (
        <img
          src="/assets/img/clear.svg"
          className="DateRangePicker-clearIcon"
          onClick={() => {
            setStartDate(null);
            setEndDate(null);
            onDatePickerChange([null, null]);
          }}
        />
      )}
      {displayPicker && (
        <div className="DateRangePicker-rangeSelector">
          <DatePicker
            selected={startDate}
            onChange={onDatePickerChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
