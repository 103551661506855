/**
 * Controller for the CDU list page
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";
import { Controller, handler, FormStore, ApiRequestsCollection } from "@noom/noomscape";
import CDUCollection from "model/collections/CDUCollection";
import CDUSearchFilter from "model/utils/CDUSearchFilter";

import CDUListPage from "views/cdu/CDUListPage/CDUListPage";


const formKey = "CDU_LIST_SEARCH";
const formKeySaved = "CDU_LIST_SEARCH__SAVED";


class CDUListController extends Controller {
  mainComponent = CDUListPage;

  title = () => "Noom Noom";

  fetch = () => {
    FormStore.insertIfEmpty(formKey, new CDUSearchFilter());
    FormStore.insertIfEmpty(formKeySaved, new CDUSearchFilter());
    CDUCollection.fetchWithFilter({
      filter: FormStore.get(formKeySaved),
    });

  };

  subscribe = (onData) => {
    CDUCollection.addChangeListener(onData);
    ApiRequestsCollection.addChangeListener(onData);
    FormStore.addListener(formKey, onData);
    FormStore.addListener(formKeySaved, onData);
  };

  unsubscribe = (onData) => {
    CDUCollection.removeChangeListener(onData);
    ApiRequestsCollection.removeChangeListener(onData);
    FormStore.removeListener(formKey, onData);
    FormStore.removeListener(formKeySaved, onData);
  };

  onFilterNow = () => {
    FormStore.update(formKeySaved, FormStore.get(formKey));
    CDUCollection.fetchWithFilter({
      filter: FormStore.get(formKeySaved),
    });
  };

  onFilterDelayed = _.debounce(this.onFilterNow, 750);

  @handler
  onBoolFilterChange = (key) => () => {
    FormStore.update(formKey, {
      [key]: !_.get(FormStore.get(formKey), key),
    });
    this.onFilterDelayed();
  };

  @handler
  onTextFilterChange = (key) => (evt) => {
    FormStore.update(formKey, {
      [key]: evt.currentTarget.value,
    });
    this.onFilterDelayed();
  };

  @handler
  onValueFilterChange = (key) => (value) => {
    FormStore.update(formKey, {
      [key]: value,
    });
    this.onFilterDelayed();
  };

  getData = () => {
    const savedFilter = FormStore.get(formKeySaved);
    return {
      cdus: CDUCollection.getWithFilter({
        filter: savedFilter,
      }),
      filter: FormStore.get(formKey),
      savedFilter: savedFilter,
      searchRequest: ApiRequestsCollection.get("cdu.filter"),
    };
  };

  isReady = () => {
    return (this.state.cdus);
  };
}

export default CDUListController;
