/**
 * Component for setting body class.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
*/


import React from "react";


class BodyClassName extends React.Component {

  componentDidMount = () => {
    document.body.setAttribute("class", this.props.className);
  };

  componentWillUnmount = () => {
    document.body.setAttribute("class", "");
  };

  render() {
    return null;
  }
}

export default BodyClassName;
