/**
 * Upper, immutable part of the data page
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import React from "react";
import SheetBoolField from "components/sheet/SheetBoolField";
import SheetCustomField from "components/sheet/SheetCustomField";
import SheetDateField from "components/sheet/SheetDateField";
import SheetTextField from "components/sheet/SheetTextField";
import Tableau from "model/items/Tableau";


const GrayPart = ({
  cdu,
}) => (
  <React.Fragment>
    <div className="landlookGrid-row">
      <div className="landlookGrid-column4">
        <SheetTextField
          label="Noom email"
          value={ cdu.email }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetTextField
          label="CDU access code"
          value={ cdu.accessCode }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetTextField
          label="Manager full name"
          value={ cdu.managerName }
        />
      </div>
    </div>


    <div className="landlookGrid-row">
      <div className="landlookGrid-column4">
        <SheetTextField
          label="Namely Employee ID"
          value={ cdu.namelyEmployeeId }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetTextField
          label="CDU start date"
          value={ cdu.employment && cdu.employment.dateStarted }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetTextField
          label="Manager CDU access code"
          value={ cdu.managerAccessCode }
        />
      </div>
    </div>


    <div className="landlookGrid-row">
      <div className="landlookGrid-column4 landlookGrid-columnRightBordered">
        <SheetTextField
          label="Hours"
          value={ cdu.schedule && cdu.schedule.weeklyHours }
        />
      </div>
      <div className="landlookGrid-column4 landlookGrid-columnRightBordered">
        <SheetTextField
          label="Coach role"
          value={ Tableau.roleToDisplayName(cdu.role) }
        />
      </div>
      <div className="landlookGrid-column4">
        <SheetBoolField
          label="Still in the org"
          value={ cdu.isActive }
        />
      </div>
    </div>
  </React.Fragment>
);


export default GrayPart;
