/**
 * Controller for the group creator layout.
 *
 * Copyright (C) 2019D Noom, Inc.
 * @author nikola
 */

import { Api, Controller, handler, FormStore } from "@noom/noomscape";

import { fetchGroupForUserAPI } from "../../api/group/fetchForUser";
import UserProfileSingleton from "model/collections/UserProfileSingleton";

import GroupCreatorLayout from "./GroupCreatorLayout";

const formKey = "GROUP_CREATOR_FORM";

class GroupCreatorController extends Controller {
  mainComponent = GroupCreatorLayout;

  static getDefaultState() {
    return {
      log: [],
      failed: [],
      inProgress: false,
      showReport: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = GroupCreatorController.getDefaultState();
  }

  controllerWillMount() {
    FormStore.insert(formKey, { value: "" });
  }

  subscribe = (onData) => {
    UserProfileSingleton.addChangeListener(onData);
    FormStore.addListener(formKey, onData);
  };

  unsubscribe = (onData) => {
    UserProfileSingleton.removeChangeListener(onData);
    FormStore.removeListener(formKey, onData);
  };

  @handler
  onChange = (event) => {
    FormStore.update(formKey, { value: event.currentTarget.value });
  };

  // Parse the textare input
  parse = (valueString = "") => {
    return valueString
      .trim()
      .split(/\r?\n/)
      .map((line) =>
        line
          .trim()
          .replace(/\s+/g, " ")
          .split(" ")
          .map((val) => val.trim())
      )
      .map(formatLine);
  };

  // Join parsed input to be desplayed in textarea
  join = (lines = []) => {
    return lines.map((line) => line.join(" ")).join("\n");
  };

  // Form is valid if all lines have group name, and at least 2 access codes
  // Each access code should have 8 uppercase alphanumeric characters
  isValid = () => {
    try {
      const parsedValues = this.parse(FormStore.get(formKey).value);
      return parsedValues.reduce((acc, line) => {
        const [groupName, ...accessCodes] = line;
        return (
          acc &&
          Boolean(groupName.length) &&
          accessCodes.length >= 2 &&
          acc &&
          accessCodes.reduce((acc, val) => acc && isValidAccessCode(val), true)
        );
      }, true);
    } catch (err) {
      return false;
    }
  };

  createGroup = async (name) => {
    try {
      const groupResponse = await Api.call(
        "group.create",
        Api.api.group.create,
        { name }
      );
      const groupId = groupResponse.data;
      await this.log(`Group "${name}" created with ID: ${groupId}`);
      return groupId;
    } catch (err) {
      await this.log(`Group "${name}" creation failed`, err);
      throw err;
    }
  };

  addGroupLeader = async (groupId, groupName, ufcAccessCode) => {
    try {
      await Api.call("group.addUser", Api.api.group.addUser, {
        groupId,
        isLeader: true,
        accessCode: ufcAccessCode,
      });
      await this.log(
        `Coach ${ufcAccessCode} added as leader of group "${groupName}", ID: ${groupId}`
      );
    } catch (err) {
      await this.log(
        `Failed adding coach ${ufcAccessCode} as leader of group "${groupName}",  ID: ${groupId}`,
        err
      );
      throw err;
    }
  };

  addGroupMember = async (groupId, groupName, coacheeAccessCode) => {
    try {
      await Api.call("group.addUser", Api.api.group.addUser, {
        groupId,
        isLeader: false,
        accessCode: coacheeAccessCode,
      });
      await this.log(
        `User ${coacheeAccessCode} added as member to group "${groupName}", ID: ${groupId}`
      );
    } catch (err) {
      await this.log(
        `Failed adding user ${coacheeAccessCode} as member to group "${groupName}", ID: ${groupId}`,
        err
      );
      throw err;
    }
  };

  getUserGroup = async (coacheeAccessCode) => {
    try {
      return await fetchGroupForUserAPI(coacheeAccessCode);
    } catch (err) {
      await this.log(
        `Failed getting ${coacheeAccessCode} group information`,
        err
      );
      throw err;
    }
  };

  @handler
  onCreate = async () => {
    await this.clearLog();
    await this.clearFailedLines();
    await this.setState({ inProgress: true, showReport: false });
    const lines = this.parse(FormStore.get(formKey).value);

    let groupSuccessCount = 0;
    let groupFailCount = 0;
    let userSuccessCount = 0;
    let userFailCount = 0;

    // TODO (2019D, nikola) At some point update to show error reports for each group seperatly

    for (const line of lines) {
      try {
        const [groupName, ufcAccessCode, ...coacheeAccessCodes] = line;

        const groupId = await this.createGroup(groupName);

        await this.addGroupLeader(groupId, groupName, ufcAccessCode);

        for (const coacheeAccessCode of coacheeAccessCodes) {
          try {
            const userGroup = await this.getUserGroup(coacheeAccessCode);

            // Avoid adding users to a group if they are already part of another group
            if (!userGroup) {
              await this.addGroupMember(groupId, groupName, coacheeAccessCode);
              userSuccessCount++;
            } else {
              userFailCount++;
              await this.log(
                `Failed adding user ${coacheeAccessCode} as member to group "${groupName}", ID: ${groupId}`,
                {
                  message: `User already member of group "${userGroup.name}", ID: ${userGroup.id}`,
                }
              );
            }
          } catch (e) {
            userFailCount++;
          }
        }

        groupSuccessCount++;
      } catch (err) {
        await this.addFailedLine(line);
        groupFailCount++;
        console.error(err);
      }

      await this.log("------------------------------------------");
    }

    await this.log("DONE!");
    await this.log("REPORT:");
    await this.log(
      `Groups created successfully: ${groupSuccessCount}/${
        groupSuccessCount + groupFailCount
      }`,
      groupFailCount > 0
    );
    await this.log(
      `Users added successfully: ${userSuccessCount}/${
        userSuccessCount + userFailCount
      }`,
      userFailCount > 0
    );
    await this.setState({ inProgress: false, showReport: true });
  };

  @handler
  onRetryFailed = async () => {
    const { failed } = this.state;
    FormStore.update(formKey, { value: this.join(failed) });
    await this.setState({ inProgress: false, showReport: false, log: [] });
  };

  @handler
  onRetryAll = async () => {
    await this.setState(GroupCreatorController.getDefaultState());
  };

  @handler
  onReset = async () => {
    FormStore.update(formKey, { value: "" });
    await this.setState(GroupCreatorController.getDefaultState());
  };

  getData = () => {
    return {
      log: this.state.log,
      failed: this.state.failed,
      showReport: this.state.showReport,
      inProgress: this.state.inProgress,
      user: UserProfileSingleton.get(),
      value: FormStore.get(formKey).value,
      isValid: this.isValid(),
    };
  };

  log = async (value, error) => {
    await this.setState({ log: [...this.state.log, { value, error }] });
  };

  clearLog = async () => {
    await this.setState({ log: [] });
  };

  addFailedLine = async (line) => {
    await this.setState({ failed: [...this.state.failed, line] });
  };

  clearFailedLines = async () => {
    await this.setState({ failed: [] });
  };

  isReady = () => {
    return !!this.state.user;
  };
}

function isValidAccessCode(accessCode = "") {
  return /^[A-Z0-9]{8}$/.test(accessCode);
}

// Since coaches requested removal of commas, line is formated so that everything until a valid access code
// is considered a group name
function formatLine(line = []) {
  const nameChunks = [];
  const formattedLine = [...line];

  while (!isValidAccessCode(formattedLine[0]) && formattedLine.length) {
    nameChunks.push(formattedLine.shift());
  }

  return [nameChunks.join(" "), ...formattedLine];
}

export default GroupCreatorController;
