/**
 * User profile collection shim.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
 */

import { getActiveUser } from "@noom/hive-auth";
import { DevTools, Singleton, Api } from "@noom/noomscape";

import UserProfile from "model/items/UserProfile";

class UserProfileSingleton extends Singleton {
  fetch = () => {
    if (this._item) {
      return;
    }

    const userLogin = getActiveUser();
    if (!userLogin) {
      console.log("NO USER FOUND");
      return;
    }
    Api.call("profile", Api.api.userProfile, {
      accessCode: userLogin.accessCode,
    })
      .then((data) => {
        this.set(new UserProfile(data));
      })
      .catch((error) => {
        console.log("Profile error", error);
      });
  };
}

const instance = new UserProfileSingleton();
DevTools.expose({ UserProfileSingleton: instance });
export default instance;
