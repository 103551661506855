/**
 * Endpoint for fetching list of CDUs
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import CDU from "model/items/CDU";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register(
  "cduSearch",
  ({
    query,
    showInactive,
    needsAttention,
    minEmploymentStartDate,
    maxEmploymentStartDate,
    showNmcs,
  }) => {
    const params = _.pickBy({
      includeInactive: showInactive,
      includeOnlyWithMissingAllocations: needsAttention,
      includeEligibleNmcs: showNmcs,
      minEmploymentStartDate: minEmploymentStartDate,
      maxEmploymentStartDate: maxEmploymentStartDate,
    });
    params.q = query;
    return axios({
      url: "/coaches/cdus/search",
      baseURL: process.env.HIVE_SERVER_URL,
      method: "GET",
      headers: {
        Authorization: getAuthorizationHeader(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: params,
    }).then((response) => {
      return response.data.result.map((r) =>
        _(r)
          .pick(CDU.properties)
          .assign({
            allTableaus: r.responsibilityAllocations
              ? [
                  {
                    dateStarted: "2000-01-01",
                    responsibilityAllocations: r.responsibilityAllocations,
                  },
                ]
              : undefined,
          })
          .value()
      );
    });
  }
);
