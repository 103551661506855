/**
 * View for the group remover.
 *
 * Copyright (C) 2022A Noom, Inc.
 * @author nikola
 */

import React, { FC, ChangeEventHandler } from "react";
import { Button, Textarea, useDisclosure } from "@noom/wax-component-library";

import { ScrollingLog } from "modules/tools";
import type { Result } from "./GroupRemoverController";

import "./GroupRemoverLayout.scss";

export type GroupRemoverLayoutProps = {
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onStart: () => void;
  onReset: () => void;
  onRetryAll: () => void;
  onRetryFailed: () => void;
  isValid: boolean;
  inProgress: boolean;
  showReport: boolean;
  log: any[];
  failed: any[];
  results?: Result[];
};

const GroupRemoverLayout: FC<GroupRemoverLayoutProps> = (props) => {
  const {
    value,
    onChange,
    onStart,
    onReset,
    onRetryFailed,
    isValid,
    inProgress,
    showReport,
    log,
    failed,
  } = props;

  const { isOpen, onToggle } = useDisclosure();

  return (
    <div className="landlookLayout-scrollPage groupRemoverLayout-box">
      <div className="landlookLayout-maxWrap">
        <div className="landlookGrid-box landlookSheet-box">
          <div className="landlookGrid-row">
            <div className="landlookGrid-column12">
              <div className="landlookSheet-title">Group Remover</div>
            </div>
          </div>

          <div className="landlookSheet-field">
            <div className="landlookSheet-label">Remove users from groups</div>

            <div className="landlookSheet-label">
              Add user access codes below. Tab or comma separated lines.
            </div>

            <div className="groupRemoverLayout-textWrap">
              {inProgress || showReport ? <ScrollingLog log={log} /> : null}

              {!inProgress && !showReport ? (
                <Textarea
                  autoFocus
                  className="landlookSheet-input"
                  value={value || ""}
                  onChange={onChange}
                  isDisabled={inProgress}
                />
              ) : null}

              <div className="groupRemoverLayout-buttonWrap">
                {showReport ? (
                  <>
                    <Button onClick={onReset} variantColor="secondary">
                      Reset
                    </Button>

                    {failed.length > 0 ? (
                      <Button onClick={onRetryFailed} variantColor="secondary">
                        Retry failed
                      </Button>
                    ) : null}
                  </>
                ) : null}

                {!showReport ? (
                  <Button
                    isDisabled={!isValid}
                    isLoading={inProgress}
                    onClick={onStart}
                    variantColor="secondary"
                  >
                    {inProgress ? "Working..." : "Remove"}
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupRemoverLayout;
