/**
 * Endpoint for creating groups
 *
 * Copyright (C) 2019D Noom, Inc.
 * @author nikola
 */

import axios from "axios";
import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("group.create", async ({ name }) => {
  return axios({
    url: "/groups/create",
    baseURL: process.env.HIVE_SERVER_URL,
    method: "POST",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
    params: {
      name,
      activate: true,
      language: "en",
      isTest: false,
      gmtOffsetSeconds: 0,
    },
  });
});
