/**
 * Model for CDU search filter for the main page
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author Hubert
 */

import _ from "lodash";
import { enu } from "@noom/noomscape";

class CDUSearchFilter {
  static tril = enu(["BOTH", "YES", "NO"]);

  static package = enu([
    "ALL",
    "HEALTHY_WEIGHT",
    "HEALTHY_MIND",
    "TELEHEALTH",
    "DIABETES_PREVENTION",
  ]);
  static locale = enu(["ALL", "en_US", "de", "es"]);
  static coursePack = enu([null, "SUMMIT_NUTRITION", "PREMIUM_NO_VIDEO", "PREMIUM_WITH_VIDEO"]);
  static businessType = enu(["ALL", "B2C", "B2B"]);

  static trilToValue = {
    BOTH: undefined,
    YES: true,
    NO: false,
  };

  static localeToValue = {
    ALL: undefined,
    en_US: "en_US",
    de: "de",
    es: "es",
  };

  static packageToValue = {
    ALL: undefined,
    HEALTHY_WEIGHT: "HEALTHY_WEIGHT",
    HEALTHY_MIND: "HEALTHY_MIND",
    TELEHEALTH: "TELEHEALTH",
    DIABETES_PREVENTION: "DIABETES_PREVENTION",
  };

  static coursePackToValue = {
    null: null,
    SUMMIT_NUTRITION: "SUMMIT_NUTRITION",
    PREMIUM_NO_VIDEO: "PREMIUM_NO_VIDEO", 
    PREMIUM_WITH_VIDEO: "PREMIUM_WITH_VIDEO",
  };

  static businessTypeToValue = {
    ALL: undefined,
    B2C: "B2C",
    B2B: "B2B",
  };

  static businessTypeToValue = {
    ALL: undefined,
    B2C: "B2C",
    B2B: "B2B",
  };

  static sortKey = enu([
    "NAME_ASC",
    "NAME_DESC",
    "CASELOAD_ASC",
    "CASELOAD_DESC",
  ]);

  constructor(raw) {
    _.assign(
      this,
      {
        page: 1,
        query: "",
        inRotation: CDUSearchFilter.tril.BOTH,
        inExperiment: CDUSearchFilter.tril.BOTH,
        showInactive: false,
        showNmcs: false,
        needsAttention: true,
        startDate: null,
        endDate: null,
        dateRangeFocus: undefined,
        sortKey: CDUSearchFilter.sortKey.NAME_ASC,
        programLocale: CDUSearchFilter.locale.en_US,
        programPackage: CDUSearchFilter.package.HEALTHY_WEIGHT,
        coursePack: CDUSearchFilter.coursePack.NONE,
        businessType: CDUSearchFilter.businessType.ALL,
      },
      raw
    );
  }

  toString() {
    const table = [
      `q=${encodeURIComponent(this.query)}`,
      `i=${this.showInactive ? 1 : 0}`,
      `a=${this.needsAttention ? 1 : 0}`,
      `nmcs=${this.showNmcs ? 1 : 0}`,
      `p=${this.page}`,
      `rot=${this.inRotation}`,
      `businessType=${this.businessType}`,
      `exp=${this.inExperiment}`,
      `programLocale=${this.programLocale}`,
      `progamPackage=${this.programPackage}`,
    ];
    if (this.startDate) {
      table.push(`str=${this.startDate.toLocaleDateString('en-CA')}`);
    }
    if (this.endDate) {
      table.push(`end=${this.endDate.toLocaleDateString('en-CA')}`);
    }
    if (this.sortKey) {
      table.push(`sort=${this.sortKey}`);
    }
    if (this.coursePack != undefined) {
      table.push(`coursePack=${this.coursePack}`);
    }
    return table.join("&");
  }

  isEmpty() {
    return (
      this.query.length === 0 &&
      !this.includeNmcs &&
      !this.needsAttention &&
      !this.startDate &&
      !this.endDate
    );
  }
}

export default CDUSearchFilter;
