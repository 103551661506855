import { ToolState } from "./models";

export function isValidAccessCode(accessCode = "") {
  return /^[A-Z0-9]{8}$/.test(accessCode);
}

export function getDefaultToolState<TValue, TResults>(
  value: TValue,
  results?: TResults
): ToolState<TValue, TResults> {
  return {
    value,
    failed: [],
    parsedValues: [],
    isValid: false,
    inProgress: false,
    showReport: false,
    results,
  };
}

/** Parse a string of access codes, delimited by a space, tab or new line, to a list */
export function parseAccessCodeList(valueString = "") {
  return valueString
    .trim()
    .split(/\r?\n/)
    .flatMap((line) =>
      line
        .trim()
        .replace(/\s+/g, " ")
        .replace(/,/g, " ")
        .split(" ")
        .map((val) => val.trim())
    )
    .filter(Boolean)
    .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
}

export function isValidAccessCodeList(values: string[] = []) {
  try {
    return values.reduce((acc, item) => acc && isValidAccessCode(item), true);
  } catch (err) {
    return false;
  }
}
