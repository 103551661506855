/**
 * View for the coverage page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";
import React from "react";


class CoverageHomePage extends React.Component {
  static propTypes = {

  };

  render = () => (
    <div className="landlookLayout-fixedPage">
      <div className="landlookLayout-fixedPageWrap">
        <div className="landlookLayout-tmp">
          <div className="landlookLayout-tmpTitle">Coverage!</div>
          <img
            className="landlookLayout-tmpImage"
            src="/assets/img/notdoneyet.png"
          /><br/>
        </div>
      </div>
    </div>
  );
}

export default CoverageHomePage;
