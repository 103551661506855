/**
 * Endpoint for fetching all the users handled by an UFC
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import axios from "axios";
import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register(
  "ufc.listUserAccessCodes",
  async ({ ufcAccessCode, pageSize = 1000 }) => {
    let data = [];
    let pageToken;

    do {
      const response = await axios({
        url: `/coaching/v1/ufcs/${ufcAccessCode}/users`,
        baseURL: process.env.HIVE_SERVER_URL,
        method: "GET",
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        params: {
          pageSize,
          pageToken,
        },
      });

      data = data.concat(response.data.result ?? []);
      pageToken = new URLSearchParams(response.data.nextPageUrl).get(
        "pageToken"
      );
    } while (pageToken);

    return { data: data.map((accessCode) => ({ accessCode, isActive: true })) };
  }
);
