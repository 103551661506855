/**
 * Endpoint for fetching users group information
 *
 * Copyright (C) 2019D Noom, Inc.
 * @author nikola
 */

import axios from "axios";
import { getAuthorizationHeader } from "@noom/hive-auth";
import { CoachServer } from "@noom/hive-types";

type Group = CoachServer["Group"];

export async function fetchGroupForUserAPI(
  userAccessCode: string
): Promise<Group | undefined> {
  const membershipResponse = await axios({
    url: `/users/${userAccessCode}/groupMembership`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });

  const groupId = membershipResponse.data?.[0]?.groupId;

  if (groupId) {
    const groupResponse = await axios({
      url: `/NoomGroupsServer/groups/${groupId}`,
      baseURL: process.env.HIVE_SERVER_URL,
      method: "GET",
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    });

    return groupResponse.data;
  }

  return undefined;
}
