/**
 * Controller for the main page.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
 */

import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const HomeController = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/cdu");
  }, []);
}

export default HomeController;
