/**
 * Model for CDU responsibility allocation table
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author Hubert
 */

import pick from "lodash/pick";
import { enu } from "@noom/noomscape";

const coachingAllocations = {
  INDIVIDUAL_COACH: {
    active: true,
    coaching: true,
    label: "Individual Coach (GS)",
  },
  GROUP_COACH: {
    active: true,
    coaching: true,
    label: "Group Coach",
  },
  COVERAGE_COACH: {
    active: true,
    coaching: true,
    label: "Coverage Coach (Ninja)",
  },
  GUIDE_COACH: {
    active: true,
    coaching: true,
    label: "Guide Coach",
  },
  WEEKEND_COVERAGE: {
    active: true,
    coaching: true,
    label: "Weekend Coverage Coach (Ninja)",
  },
  FWS_AGENT: {
    active: true,
    coaching: true,
    label: "FWS Agent",
  },
  ONBOARDING_CONCIERGE_COACH: {
    active: false,
    coaching: true,
    label: "Onboarding / Concierge Coach",
  },
  FWS_INDEVA: {
    active: false,
    coaching: true,
    label: "FWS Indeva",
  },
  FWS_PODFATHER: {
    active: false,
    coaching: true,
    label: "FWS Podfather",
  },
  EXPERIMENT: {
    active: false,
    coaching: true,
    label: "EXPERIMENT",
  },
  DPP: {
    active: false,
    coaching: true,
    label: "DPP",
  },
  B2B: {
    active: false,
    coaching: true,
    label: "B2B",
  },
};

const nonCoachingAllocations = {
  COACH_MANAGING: {
    active: true,
    coaching: false,
    label: "Coach Managing",
    formKey: "managing",
  },
  HIRING: {
    active: true,
    coaching: false,
    label: "Hiring",
    formKey: "hiring",
  },
  TESTING: {
    active: true,
    coaching: false,
    label: "Testing",
    formKey: "testing",
  },
  PM_CPC: {
    active: true,
    coaching: false,
    label: "Project Management: CPC",
    formKey: "pm_cpc",
  },
  PM_CPM: {
    active: true,
    coaching: false,
    label: "Project Management: CPM",
    formKey: "pm_cpm",
  },
  OTHER: {
    active: true,
    coaching: false,
    label: "Other",
    formKey: "other",
  },
  MEAL_EXERCISE_PLANS: {
    active: false,
    coaching: false,
    label: "Meal Exercise Plans",
    formKey: "plans",
  },
};

const typeInfo = {
  ...coachingAllocations,
  ...nonCoachingAllocations,
};

class Tableau {
  static types = enu(typeInfo);
  static typeInfo = typeInfo;
  static coachingAllocations = coachingAllocations;
  static nonCoachingAllocations = nonCoachingAllocations;
  static coachingTypeOptions = Object.entries(coachingAllocations)
    .map(([k, v]) => {
      return { key: k, label: v.label, coaching: v.coaching, active: v.active };
    })
    .filter((x) => x.active);

  static properties = [
    "allocationUuid",
    "dateStarted",
    "dateEnded",
    "isTemporary",
    "responsibilityAllocations",
  ];

  static itemProperties = ["responsibility", "percent"];

  static roleToDisplayName = (role) => {
    return Tableau.typeInfo[role] ? Tableau.typeInfo[role].label : "UNKNOWN";
  };

  static emptyFormData() {
    return {
      coachingAs: "",
      coaching: 0,
      dateStarted: "",
      dateEnded: "",
      ...Object.values(nonCoachingAllocations).reduce((curr, ca) => {
        curr[ca.formKey] = 0;
        return curr;
      }, {}),
    };
  }

  static isFormDataTotalCorrect(formData) {
    return (
      Object.values(nonCoachingAllocations)
        .map((ca) => ca.formKey)
        .concat(["coaching"])
        .reduce((sum, key) => {
          sum += formData[key] || 0;
          return sum;
        }, 0) === 100
    );
  }

  static responsibilityAllocationsFromFormData(formData) {
    return Object.keys(nonCoachingAllocations)
      .map((caKey) => {
        const caValue = nonCoachingAllocations[caKey];

        return {
          responsibility: Tableau.types[caKey],
          percent: formData[caValue.formKey],
        };
      })
      .concat({
        responsibility: formData.coachingAs,
        percent: formData.coaching,
      })
      .filter((ca) => ca.percent > 0);
  }

  constructor(raw) {
    raw = raw || {};
    Object.assign(this, pick(raw, Tableau.properties));

    this.responsibilityAllocations = raw.responsibilityAllocations
      .map((x) => pick(x, Tableau.itemProperties))
      .sort((raa, rab) => rab.percent - raa.percent);

    this.topResponsibility = this.responsibilityAllocations.find(
      (x) =>
        Tableau.typeInfo[x.responsibility] &&
        Tableau.typeInfo[x.responsibility].coaching
    );
  }

  get coachingRole() {
    const ra = this.responsibilityAllocations.find(
      (x) => Tableau.typeInfo[x.responsibility].coaching
    );
    return ra ? ra.responsibility : null;
  }

  allocationPercentForRole(role) {
    const ra = this.responsibilityAllocations.find(
      (x) => x.responsibility === role
    );
    return ra ? ra.percent : null;
  }

  isEqualToFormData(data) {
    const thisData = this.asFormData;
    return [
      "coachingAs",
      "coaching",
      "dateStarted",
      "dateEnded",
      ...Object.values(nonCoachingAllocations).map((ca) => ca.formKey),
    ].every((param) => data[param] === thisData[param]);
  }

  get asFormData() {
    const role = this.coachingRole;
    return {
      coachingAs: role || undefined,
      coaching: this.allocationPercentForRole(role) || 0,
      dateStarted: this.dateStarted,
      dateEnded: this.dateEnded,
      ...Object.entries(nonCoachingAllocations).reduce(
        (curr, [caKey, caValue]) => {
          curr[caValue.formKey] =
            this.allocationPercentForRole(Tableau.types[caKey]) || 0;
          return curr;
        },
        {}
      ),
    };
  }

  get topResponsibilityName() {
    if (this.topResponsibility) {
      return Tableau.typeInfo[this.topResponsibility.responsibility].label;
    }
    return "(no coaching role)";
  }
}

export default Tableau;
