/**
 * Lower part of the data page in edit mode
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import SheetBoolField from "components/sheet/SheetBoolField";
import SheetCustomField from "components/sheet/SheetCustomField";
import Weekdays from "components/etc/Weekdays";
import WhiteFormTableau from "views/cdu/CDUEditPage/WhiteFormTableau";
import CDU from "model/items/CDU";
import Employment from "model/items/Employment";
import { If } from "@noom/noomscape";

const WhiteForm = ({
  cdu,
  formData,
  onCancel,
  onSave,

  onInputChange,
  onNumberChange,
  onValueChange,
  onToggleChange,
  onCreateTemporaryTableau,
  onRemoveTemporaryTableau,
}) => (
  <div className="whitePart-box">
    <div className="landlookGrid-box landlookSheet-box">
      <div className="whitePart-menu">
        <button
          disabled={!formData.isReady}
          className="whitePart-menuWhiteButton"
          onClick={onCancel}
        >
          &times;
        </button>
      </div>

      <div className="landlookGrid-row">
        <div className="landlookGrid-column4">
          <SheetBoolField
            label="Whether in ramp up or not"
            value={formData.rampUp}
            editMode={true}
            onChange={onValueChange("rampUp")}
          />
        </div>
        <div className="landlookGrid-column4">
          <SheetBoolField
            label="Full Time"
            value={formData.hasFixedSchedule}
            editMode={true}
            onChange={onValueChange("hasFixedSchedule")}
          />
        </div>
        <div className="landlookGrid-column4">
          <If
            if={formData.scheduleErrors}
            then={() => (
              <div className="whitePart-errors">
                {_.map(formData.scheduleErrors, (error, idx) => (
                  <div key={idx} className="whitePart-error">
                    {error}
                  </div>
                ))}
              </div>
            )}
          />
          <SheetCustomField label="Schedule">
            <If
              if={!formData.hasFixedSchedule}
              then={() => <span>Per Diem</span>}
              else={() => (
                <Weekdays
                  editMode={true}
                  keyPrefix="schedule"
                  onChange={onToggleChange}
                  schedule={formData.schedule}
                />
              )}
            />
          </SheetCustomField>
        </div>
        <div className="landlookGrid-column4">
          <SheetCustomField label="Language for employment">
            <select
              className="whitePart-selectInput"
              value={formData.language}
              onChange={onInputChange("language")}
            >
              {Employment.languages.map((language) => (
                <option key={language} value={language}>
                  {language}
                </option>
              ))}
            </select>
          </SheetCustomField>
        </div>
      </div>

      <div className="landlookGrid-hr" />

      <div className="landlookGrid-row">
        <div className="landlookGrid-column6">
          <WhiteFormTableau
            formData={formData}
            hasTableau={true}
            baseline={true}
            keyPrefix={"baselineTableau"}
            errors={formData.baselineErrors}
            onInputChange={onInputChange}
            onNumberChange={onNumberChange}
          />
        </div>
        <div className="landlookGrid-column6">
          <WhiteFormTableau
            formData={formData}
            hasTableau={!!formData.temporaryTableau}
            baseline={false}
            keyPrefix={"temporaryTableau"}
            errors={formData.temporaryErrors}
            onInputChange={onInputChange}
            onNumberChange={onNumberChange}
            onValueChange={onValueChange}
            onCreateTemporaryTableau={onCreateTemporaryTableau}
            onRemoveTemporaryTableau={onRemoveTemporaryTableau}
          />
        </div>
      </div>

      <div className="whitePart-menu">
        <div className="whitePart-menuButton" onClick={onSave}>
          Save
        </div>
      </div>
    </div>
  </div>
);

WhiteForm.propTypes = {
  cdu: PropTypes.instanceOf(CDU).isRequired,
  formData: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,

  onInputChange: PropTypes.func.isRequired,
  onNumberChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onToggleChange: PropTypes.func.isRequired,
  onCreateTemporaryTableau: PropTypes.func.isRequired,
  onRemoveTemporaryTableau: PropTypes.func.isRequired,
};

export default WhiteForm;
