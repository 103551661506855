import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./ScrollingLog.scss";

const ScrollingLog = ({ log }) => {
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView();
    }
  }, [log]);

  return (
    <div className="scrolling-log">
      <ul>
        {log.map((logItem, index) => (
          <li
            key={index}
            className={classnames({
              error: logItem.error,
            })}
          >
            <div>{logItem.value}</div>
            {logItem.error?.message ? (
              <div>Error: {logItem.error?.message}</div>
            ) : null}
            {logItem.error?.response?.data ? (
              <div>
                {logItem.error.response.data.errorCode}:{" "}
                {logItem.error.response.data.errorMessage}
              </div>
            ) : null}
          </li>
        ))}
      </ul>
      <div ref={bottomRef} />
    </div>
  );
};

ScrollingLog.propTypes = {
  log: PropTypes.array,
};

export default memo(ScrollingLog);
