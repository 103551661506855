/**
 * Controller for the assignment page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import PropTypes from "prop-types";
import { Controller, handler, ApiRequestsCollection } from "@noom/noomscape";

import SettingsCollection from "model/collections/SettingsCollection";

import AssignmentsSettingsModal from "views/assignments/AssignmentsHomePage/AssignmentsSettingsModal";


class AssignmentsSettingsModalController extends Controller {
  static contextTypes = {
    popModal: PropTypes.func.isRequired,
    showToast: PropTypes.func,
    showSuccess: PropTypes.func,
    showError: PropTypes.func,
  };

  mainComponent = AssignmentsSettingsModal;

  fetch = () => {
    SettingsCollection.fetchAssignmentsSettings();
  };

  subscribe = (onData) => {
    SettingsCollection.addChangeListener(onData);
    ApiRequestsCollection.addChangeListener(onData);
  };

  unsubscribe = (onData) => {
    SettingsCollection.removeChangeListener(onData);
    ApiRequestsCollection.removeChangeListener(onData);
  };

  @handler
  onToggleAssignmentsAutoMode = (value) => () => {
    SettingsCollection.updateAssignmentsSettings({
      isAutoModeOn: value,
    })
      .catch((error) => {
        console.log("AssignmentsSettingsModalController ERROR:", error);
        this.context.showError(error.toString());
      });
  };

  @handler
  onDismiss = () => {
    // TODO: Replace context api when moving to component library
    this.context.popModal();
  };


  getData = () => {
    return {
      settings: SettingsCollection.getAssignmentsSettings(),
      request: ApiRequestsCollection.get("settings.assignments.isAutoModeOn"),
    };
  };

  isReady = () => {
    return (this.state.settings);
  };
}


export default AssignmentsSettingsModalController;
