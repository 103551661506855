/**
 * Endpoint for updating assignment data
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";
import Tableau from "model/items/Tableau";

Api.register(
  "cduDataUpdateTableau",
  ({ cduAccessCode, tableau, tableauId }) => {
    return axios({
      url: `/coaches/cdus/${cduAccessCode}/responsibilityAllocations/${tableauId}`,
      baseURL: process.env.HIVE_SERVER_URL,
      method: "PUT",
      data: {
        responsibilityAllocations:
          Tableau.responsibilityAllocationsFromFormData(tableau),
        isTemporary: true,
        dateStarted: tableau.dateStarted,
        dateEnded: tableau.dateEnded,
      },
      headers: {
        Authorization: getAuthorizationHeader(),
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return "ok";
      })
      .catch((error) => {
        console.log("UPDATE TABLEAU ERROR", error);
        throw error;
      });
  }
);
