/**
 * Tableau editor for the lower part of the data page
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";
import React from "react";
import moment from "moment";
import { If } from "@noom/noomscape";
import Tableau from "model/items/Tableau";


const WhiteFormTableau = ({
  formData,
  hasTableau,
  baseline,
  keyPrefix,
  errors,
  onInputChange,
  onNumberChange,
  onValueChange,
  onCreateTemporaryTableau,
  onRemoveTemporaryTableau,
}) => (
  <If
    if={ hasTableau }
    then={ () => (
      <div className={ `whitePart-tableau ${baseline ? "whitePart-tableauActive" : ""}` }>
        <div className="whitePart-tableauHeader">
          <div className="whitePart-tableauTitle">
            { baseline ? "Baseline Allocation" : "Temporary" }
          </div>
          <If
            if={ !baseline }
            then={ () => (
              <div
                className="whitePart-tableauRemoveButton"
                onClick={ onRemoveTemporaryTableau }
              >
                REMOVE
              </div>
            ) }
          />
        </div>

        <If
          if={ errors }
          then={ () => (
            <div className="whitePart-errors">
              { _.map(errors, (error, idx) => (
                <div
                  key={ idx }
                  className="whitePart-error"
                >
                  { error }
                </div>
              )) }
            </div>
          ) }
        />

        <div className="whitePart-line">
          <div className="whitePart-lineLabel">
            Coaching as
          </div>
          <div className="whitePart-lineContent">
            <select
              className="whitePart-selectInput"
              value={ formData[keyPrefix]?.coachingAs }
              onChange={ onInputChange(`${keyPrefix}.coachingAs`) }
            >
              <option value=""></option>
              { _.map(Tableau.coachingTypeOptions, option => (
                <option
                  key={ option.key }
                  value={ option.key }
                >
                  { option.label }
                </option>
              )) }
            </select>
            <input
              type="number"
              min="0"
              max="100"
              value={ String(formData[keyPrefix]?.coaching) }
              onChange={ onNumberChange(`${keyPrefix}.coaching`) }
              className="whitePart-percentInput"
            />
          </div>
        </div>

        { Object.keys(Tableau.nonCoachingAllocations).map(allocationKey => {
          const allocation = Tableau.nonCoachingAllocations[allocationKey];
          if(!allocation.active) {
            return null;
          }

          const value = String(formData[keyPrefix] ? formData[keyPrefix][allocation.formKey] : "");

          return (
            <div
              key={ allocationKey }
              className="whitePart-line"
            >
              <div className="whitePart-lineLabel">
                { allocation.label }
              </div>
              <div className="whitePart-lineContent">
                <input
                  type="number"
                  min="0"
                  max="100"
                  value={ value }
                  onChange={ onNumberChange(`${keyPrefix}.${[allocation.formKey]}`) }
                  className="whitePart-percentInput"
                />
              </div>
            </div>
          );
        }) }

        <If
          if={ !baseline }
          then={ () => (
            <React.Fragment>

              <div className="whitePart-line">
                <div className="whitePart-lineLabel">
                  Effective start date
                </div>
                <div className="whitePart-lineContent">
                  <input
                    type="date"
                    value={ formData[keyPrefix]?.dateStarted }
                    onChange={ onInputChange(`${keyPrefix}.dateStarted`) }
                    className="whitePart-input"
                    min={ moment().format("YYYY-MM-DD") }
                  />
                </div>
              </div>

              <div className="whitePart-line">
                <div className="whitePart-lineLabel">
                  Effective end date
                </div>
                <div className="whitePart-lineContent">
                  <input
                    type="date"
                    value={ formData[keyPrefix]?.dateEnded }
                    onChange={ onInputChange(`${keyPrefix}.dateEnded`) }
                    className="whitePart-input"
                    min={ formData[keyPrefix]?.dateStarted || moment().format("YYYY-MM-DD") }
                  />
                </div>
              </div>
            </React.Fragment>
          ) }
        />
      </div>
    ) }
    else={ () => (
      <div className='whitePart-tableau'>
        <div className="whitePart-tableauHeader">
          <div
            className="whitePart-tableauTitleButton"
            onClick={ onCreateTemporaryTableau }
          >
            Add Temporary Allocation
          </div>
        </div>
      </div>
    ) }
  />
);


export default WhiteFormTableau;
