/**
 * Controller for the group creator layout.
 *
 * Copyright (C) 2021C Noom, Inc.
 * @author nikola
 */

import React, { ChangeEventHandler, FC } from "react";
import { useSetState, useList } from "react-use";
import { Api } from "@noom/noomscape";
import { CoachServer } from "@noom/hive-types";

import {
  Logger,
  ToolLog,
  ToolState,
  useToolLog,
  getDefaultToolState,
  parseAccessCodeList,
  isValidAccessCodeList,
} from "modules/tools";

import { fetchGroupForUserAPI } from "../../api/group/fetchForUser";

const BATCH_SIZE = 25;

import UserCheckerLayout from "./UserCheckerLayout";

export type Result = {
  userAccessCode: string;
  userGroup?: { name: string };
  profile?: CoachServer["UserProfile"];
};

export type State = ToolState<string, Result[]>;

function getDefaultState(): State {
  return getDefaultToolState("");
}

// Join parsed input to be desplayed in textarea
function join(lines: State["parsedValues"] = []) {
  return lines.join("\n");
}

async function getUserAndGroupData(userAccessCode: string, log?: Logger) {
  try {
    const profile = await Api.call("user.getProfile", Api.api.user.getProfile, {
      userAccessCode,
      full: true,
    });
    const group = await fetchGroupForUserAPI(userAccessCode);

    log?.(
      `User ${userAccessCode} (lang: ${profile?.language}, platform: ${
        profile?.mostRecentPlatform
      }) has ${
        group
          ? `a group - ${group?.name} (lang: ${group?.language})`
          : "no groups"
      }`
    );
    return { userAccessCode, userGroup: group, profile } as Result;
  } catch (err) {
    log?.(`Failed getting ${userAccessCode} information`, err as Error);
    throw err;
  }
}

async function start(lines: State["parsedValues"], log?: Logger) {
  let userSuccessCount = 0;
  let userFailCount = 0;

  let failLines: string[] = [];
  let results: Result[] = [];

  for (let index = 0; index < lines.length; index += BATCH_SIZE) {
    const batch = lines.slice(index, index + BATCH_SIZE);

    const resultList = await Promise.allSettled<Promise<Result>>(
      batch.map((line) => getUserAndGroupData(line, log))
    );

    const successList = resultList
      .filter((result) => result.status === "fulfilled")
      .map((result) => (result as PromiseFulfilledResult<Result>).value);

    const failList = batch.filter((_accessCode, index) => {
      return resultList[index]?.status === "rejected";
    });

    results = results.concat(successList);
    failLines = failLines.concat(failList);

    userSuccessCount += successList.length;
    userFailCount += batch.length - successList.length;
  }

  log?.("DONE!");
  log?.("REPORT:");
  log?.(
    `Users tested successfully: ${userSuccessCount}/${
      userSuccessCount + userFailCount
    }`,
    userFailCount > 0
  );

  return {
    successCount: userSuccessCount,
    failCount: userFailCount,
    results,
    failed: failLines,
  };
}

const UserCheckerController: FC = () => {
  const [state, setState] = useSetState<State>(getDefaultState());
  const [log, updateLog, clearLog] = useToolLog();

  const clearFailedLines = () => {
    setState({ failed: [] });
  };

  const onRetryFailed = async () => {
    setState({
      ...getDefaultState(),
      value: join(state.failed),
      parsedValues: state.failed,
      inProgress: false,
      showReport: false,
      isValid: true,
    });
    clearLog();
  };

  const onRetryAll = () => {
    setState(getDefaultState());
  };

  const onReset = () => {
    setState(getDefaultState());
  };

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const value = event.currentTarget.value;
    const parsedValues = parseAccessCodeList(value);
    const valid = isValidAccessCodeList(parsedValues);
    setState({ value, parsedValues, isValid: valid });
  };

  const onCreate = async () => {
    clearLog();
    clearFailedLines();
    setState({ inProgress: true, showReport: false });
    const { failed, results } = await start(state.parsedValues, updateLog);
    setState({ inProgress: false, showReport: true, failed, results });
  };

  return (
    <UserCheckerLayout
      value={state.value}
      log={log}
      results={state.results}
      isValid={state.isValid}
      failed={state.failed}
      showReport={state.showReport}
      inProgress={state.inProgress}
      onRetryFailed={onRetryFailed}
      onRetryAll={onRetryAll}
      onReset={onReset}
      onChange={onChange}
      onCreate={onCreate}
    />
  );
};

export default UserCheckerController;
