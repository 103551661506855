/**
 * Controller for the main layout.
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author hubert
 */

import React from "react";
import { Controller, handler } from "@noom/noomscape";
import { useNavigate } from "react-router";
import UserProfileSingleton from "model/collections/UserProfileSingleton";

import MainLayout from "views/main/MainLayout/MainLayout";

// Work around for react router v6
export const withRouter = (Component) => {
  const Wrapper = (props) =>{
      const history = useNavigate();
      return <Component history={history} {...props}/>
  }
  return Wrapper;
}

class MainController extends Controller {
  mainComponent = MainLayout;

  subscribe = (onData) => {
    UserProfileSingleton.addChangeListener(onData);
  };

  unsubscribe = (onData) => {
    UserProfileSingleton.removeChangeListener(onData);
  };

  fetch = () => {
    UserProfileSingleton.fetch();
  };

  @handler
  onLogout = () => {
    this.props.history.push("/logout");
  };

  getData = () => {
    return {
      user: UserProfileSingleton.get(),
    };
  };

  isReady = () => {
    return !!this.state.user;
  };
}

export default withRouter(MainController);
