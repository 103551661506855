/**
 * Model for CDU assignment data
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author Hubert
*/


import _ from "lodash";
import { Item } from "@noom/noomscape";


class Assignment extends Item {

  static properties = [
    "assignToUfcAccessCode",
    "assignToUfcOptions",
    "assignToUfcOptionsCount",
    "canCoachExperimentUsers",
    "caseload",
    "cduAccessCode",
    "email",
    "firstName",
    "fteCount",
    "isInRotation",
    "businessType",
    "lastName",
    "maxCaseload",
    "maxNewCoacheesPerDay",
    "programPackage",
    "programLocale",
    "coursePack",
    "coachType"
  ];

  static optionProperties = [
    "accessCode",
    "age",
    "coachingLocale",
    "description",
    "gender",
    "isActive",
    "isConcierge",
    "name",
  ];
  
  static editableProperties = [
    "assignToUfcAccessCode",
    "isInRotation",
    "businessType",
    "maxCaseload",
    "maxNewCoacheesPerDay",
    "programPackage",
    "programLocale",
    "coursePack",
  ];

  constructor(raw) {
    super();
    _.assign(this, _.pick(raw, Assignment.properties));
    this.assignToUfcOptions = _.filter(this.assignToUfcOptions);
    _.each(this.assignToUfcOptions, option => {
      _.assign(option, {
        label: option.name,
        value: option.accessCode,
        caseload: option.caseload,
      });
    });
    this.key = raw.cduAccessCode;
  }

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  get sortBy() {
    return `${this.lastName} ${this.firstName}`;
  }

}


export default Assignment;
