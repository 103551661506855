/**
 * View for the off-boarding tool
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import React from "react";
import {
  Text,
  ModalConfirm,
  Button,
  VariantColor,
} from "@noom/wax-component-library";

import { ToolLog, ScrollingLog } from "modules/tools";

import OffboardingForm, {
  OutputValue,
  OffboardingFormProps,
} from "./OffboardingForm";
import "./OffboardingLayout.scss";

type OffboardingLayoutProps = {
  onCancel: () => void;
  onStart: () => void;
  onSubmit: OffboardingFormProps["onSubmit"];
  onReset: () => void;
  onRetryAll: () => void;
  inProgress?: boolean;
  showReport?: boolean;
  log: ToolLog[];
  failed: string[];
  showConfirmationModal?: boolean;
  value: OutputValue;
};

const OffboardingLayout = (props: OffboardingLayoutProps) => {
  const {
    onSubmit,
    onCancel,
    onStart,
    onReset,
    onRetryAll,
    inProgress,
    showReport,
    log,
    value,
    showConfirmationModal = false,
  } = props;

  const showForm = !inProgress && !showReport;

  return (
    <div className="landlookLayout-scrollPage offboardingLayout-box">
      <div className="landlookLayout-maxWrap">
        <div className="landlookGrid-box landlookSheet-box">
          <div className="landlookGrid-row">
            <div className="landlookGrid-column12">
              <div className="landlookSheet-title">Offboarding</div>
            </div>
          </div>

          {showForm ? (
            <OffboardingForm onSubmit={onSubmit} defaultValues={value} />
          ) : (
            <div>
              <ScrollingLog log={log} />

              <div className="offboardingLayout-buttonWrap">
                <Button
                  onClick={onReset}
                  disabled={inProgress}
                  variantColor={VariantColor.secondary}
                >
                  Reset
                </Button>

                <Button
                  onClick={onRetryAll}
                  variantColor={VariantColor.secondary}
                  disabled={inProgress}
                >
                  Retry
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalConfirm
        isOpen={showConfirmationModal}
        onClose={onCancel}
        onConfirm={onStart}
        variantColor={VariantColor.warning}
      >
        <Text as="p">
          Are you sure you want to offboard the <b>{value.sourceUfc}</b>?
        </Text>
      </ModalConfirm>
    </div>
  );
};

export default OffboardingLayout;
