/**
 * Component for filtering CDU list
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import PropTypes from "prop-types";

import { ApiRequest, Waiter, If } from "@noom/noomscape";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";
import CDUSearchFilter from "model/utils/CDUSearchFilter";

import "./CDUFilterBox.scss";

const CDUFilterBox = ({
  options,
  filter,
  onTextFilterChange,
  onValueFilterChange,
  searchRequest,
}) => (
  <div className="landlookHorizontalForm-part CDUFilterBox-box">
    <If
      if={options.inExperiment}
      then={() => (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            Experiment:{" "}
            <span className="CDUFilterBoxDropdown-label">
              {filter.inExperiment}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inExperiment === CDUSearchFilter.tril.BOTH
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  onValueFilterChange("inExperiment")(CDUSearchFilter.tril.BOTH)
                }
              >
                Both
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inExperiment === CDUSearchFilter.tril.YES
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  onValueFilterChange("inExperiment")(CDUSearchFilter.tril.YES)
                }
              >
                Yes
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inExperiment === CDUSearchFilter.tril.NO
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  onValueFilterChange("inExperiment")(CDUSearchFilter.tril.NO)
                }
              >
                No
              </div>
            </div>
          </div>
        </div>
      )}
    />
    <If
      if={options.localeFilter}
      then={() => (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            Locale:{" "}
            <span className="CDUFilterBoxDropdown-label">
              {filter.programLocale}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              {_.map(CDUSearchFilter.locale, (locale) => (
                <div
                  className={`CDUFilterBoxDropdown-button ${
                    filter.programLocale === locale ? "active" : ""
                  }`}
                  onClick={() => onValueFilterChange("programLocale")(locale)}
                  key={locale}
                >
                  {CDUSearchFilter.localeToValue[locale] ?? "All"}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    />

    <If
      if={options.packageFilter}
      then={() => (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            Package:{" "}
            <span className="CDUFilterBoxDropdown-label">
              {filter.programPackage}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              {_.map(CDUSearchFilter.package, (pkg) => (
                <div
                  className={`CDUFilterBoxDropdown-button ${
                    filter.programPackage === pkg ? "active" : ""
                  }`}
                  onClick={() => onValueFilterChange("programPackage")(pkg)}
                  key={pkg}
                >
                  {CDUSearchFilter.packageToValue[pkg] ?? "All"}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    />

    <If
      if={options.inRotation}
      then={() => (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            Assigned:{" "}
            <span className="CDUFilterBoxDropdown-label">
              {filter.inRotation}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inRotation === CDUSearchFilter.tril.BOTH
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  onValueFilterChange("inRotation")(CDUSearchFilter.tril.BOTH)
                }
              >
                Both
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inRotation === CDUSearchFilter.tril.YES ? "active" : ""
                }`}
                onClick={() =>
                  onValueFilterChange("inRotation")(CDUSearchFilter.tril.YES)
                }
              >
                Yes
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inRotation === CDUSearchFilter.tril.NO ? "active" : ""
                }`}
                onClick={() =>
                  onValueFilterChange("inRotation")(CDUSearchFilter.tril.NO)
                }
              >
                No
              </div>
            </div>
          </div>
        </div>
      )}
    />

    <If
      if={options.businessType}
      then={() => (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            Business Type:{" "}
            <span className="CDUFilterBoxDropdown-label">
              {filter.businessType}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.businessType === CDUSearchFilter.businessType.ALL
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  onValueFilterChange("businessType")(CDUSearchFilter.businessType.ALL)
                }
              >
                All
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.businessType === CDUSearchFilter.businessType.B2C ? "active" : ""
                }`}
                onClick={() =>
                  onValueFilterChange("businessType")(CDUSearchFilter.businessType.B2C)
                }
              >
                B2C
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.businessType === CDUSearchFilter.businessType.B2B ? "active" : ""
                }`}
                onClick={() =>
                  onValueFilterChange("businessType")(CDUSearchFilter.businessType.B2B)
                }
              >
                B2B
              </div>
            </div>
          </div>
        </div>
      )}
    />

    {
      options.coursePackFilter ?
      (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            Course Pack:{" "}
            <span className="CDUFilterBoxDropdown-label">
              {filter.coursePack === CDUSearchFilter.coursePack.NONE ? "ALL" : filter.coursePack}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.coursePack === CDUSearchFilter.coursePack.NONE
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  onValueFilterChange("coursePack")(CDUSearchFilter.coursePack.NONE)
                }
              >
                All
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inRotation === CDUSearchFilter.coursePack.SUMMIT_NUTRITION ? "active" : ""
                }`}
                onClick={() =>
                  onValueFilterChange("coursePack")(CDUSearchFilter.coursePack.SUMMIT_NUTRITION)
                }
              >
                Summit Nutrition
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inRotation === CDUSearchFilter.coursePack.PREMIUM_NO_VIDEO ? "active" : ""
                }`}
                onClick={() =>
                  onValueFilterChange("coursePack")(CDUSearchFilter.coursePack.PREMIUM_NO_VIDEO)
                }
              >
                Premium: No Video
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.inRotation === CDUSearchFilter.coursePack.PREMIUM_WITH_VIDEO ? "active" : ""
                }`}
                onClick={() =>
                  onValueFilterChange("coursePack")(CDUSearchFilter.coursePack.PREMIUM_WITH_VIDEO)
                }
              >
                Premium: With Video
              </div>
            </div>
          </div>
        </div>
      ) : null
    }

    <If
      if={options.activity}
      then={() => (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            Inactive: &nbsp;
            <span className="CDUFilterBoxDropdown-label">
              {filter.showInactive ? "Both" : "No"}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.showInactive ? "active" : ""
                }`}
                onClick={() => onValueFilterChange("showInactive")(true)}
              >
                Both
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.showInactive ? "" : "active"
                }`}
                onClick={() => onValueFilterChange("showInactive")(false)}
              >
                No
              </div>
            </div>
          </div>
        </div>
      )}
    />

    <If
      if={options.nmcs}
      then={() => (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            NMCs: &nbsp;
            <span className="CDUFilterBoxDropdown-label">
              {filter.showNmcs ? "Yes" : "No"}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.showNmcs ? "active" : ""
                }`}
                onClick={() => onValueFilterChange("showNmcs")(true)}
              >
                Yes
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.showNmcs ? "" : "active"
                }`}
                onClick={() => onValueFilterChange("showNmcs")(false)}
              >
                No
              </div>
            </div>
          </div>
        </div>
      )}
    />

    <If
      if={options.attention}
      then={() => (
        <div className="CDUFilterBoxDropdown-box">
          <div className="CDUFilterBoxDropdown-toggle">
            Needs attention: &nbsp;
            <span className="CDUFilterBoxDropdown-label">
              {filter.needsAttention ? "Yes" : "Both"}
            </span>
          </div>
          <div className="CDUFilterBoxDropdown-menu">
            <div className="CDUFilterBoxDropdown-buttons">
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.needsAttention ? "" : "active"
                }`}
                onClick={() => onValueFilterChange("needsAttention")(false)}
              >
                Both
              </div>
              <div
                className={`CDUFilterBoxDropdown-button ${
                  filter.needsAttention ? "active" : ""
                }`}
                onClick={() => onValueFilterChange("needsAttention")(true)}
              >
                Yes
              </div>
            </div>
          </div>
        </div>
      )}
    />

    <If
      if={options.dates}
      then={() => (
        <div className="landlookFormField-box">
          <DateRangePicker
            initialStartDate={filter.startDate}
            initialEndDate={filter.endDate}
            onChange={([startDate, endDate]) => {
              onValueFilterChange("startDate")(startDate);
              onValueFilterChange("endDate")(endDate);
            }}
          />
        </div>
      )}
    />

    <div className="landlookFormField-box">
      <input
        className="landlookFormField-field"
        type="text"
        value={filter.query}
        onChange={onTextFilterChange("query")}
      />
      <div className="landlookFormField-postLabel">
        <Waiter
          request={searchRequest}
          waiting={() => <span>Search</span>}
          loading={() => <span>LOADING...</span>}
          successAs="waiting"
          error={() => <span>ERROR!</span>}
        />
      </div>
    </div>
  </div>
);

CDUFilterBox.propTypes = {
  filter: PropTypes.instanceOf(CDUSearchFilter).isRequired,
  searchRequest: PropTypes.instanceOf(ApiRequest),
  onBoolFilterChange: PropTypes.func.isRequired,
  onTextFilterChange: PropTypes.func.isRequired,
};

export default CDUFilterBox;
