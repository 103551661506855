/**
 * Main React component.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import React from "react";
import {
  AuthContext,
  SecureRoute,
  LoginOneLogin,
  LoginCallbackOneLogin,
  Logout,
} from "@noom/hive-auth";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IconLibrary, ModalContext, ToastContext } from "@noom/noomscape";
import { LoaderView } from "@noom/wax-component-library";

import MainController from "views/main/MainLayout/MainController";
import HomeController from "views/main/HomePage/HomeController";

import CDUListController from "views/cdu/CDUListPage/CDUListController";
import CDUEditController from "views/cdu/CDUEditPage/CDUEditController";
import UserProfileController from "views/user/UserProfilePage/UserProfileController";
import AssignmentsHomeController from "views/assignments/AssignmentsHomePage/AssignmentsHomeController";
import VacationHomeController from "views/vacation/VacationHomePage/VacationHomeController";
import CoverageHomeController from "views/coverage/CoverageHomePage/CoverageHomeController";
import GroupCreatorController from "views/groupCreator/GroupCreatorController";
import OffboardingController from "views/offboarding/OffboardingController";
import BulkAssignmentController from "views/bulkAssignement/BulkAssignmentController";
import UserCheckerController from "views/groupMembershipChecker/UserCheckerController";
import GroupRemoverController from "views/groupRemover/GroupRemoverController";

const csServerUrl = process.env.HIVE_SERVER_URL ?? "";
const oneLoginClientId = process.env.HIVE_ONE_LOGIN_CLIENT_ID ?? "";

// Authentication configuration
const config = {
  coachServer: {
    serverUrl: csServerUrl,
    clientId: "coach-dashboard",
  },

  oneLogin: {
    clientId: oneLoginClientId,
  },

  storage: "cookie",
};

const App = () => {
  return (
    <AuthContext
      config={config}
      fallback={<LoaderView />}
    >
      <ToastContext>
        <ModalContext>
          <BrowserRouter>
            <Routes>
              <Route path="/login/callback" element={<LoginCallbackOneLogin />} />

              <Route path="/login" element={<LoginOneLogin callbackPath="/login/callback" />} />

              <Route path="/logout" element={<Logout />} />

              <Route path="/*" element={
                <SecureRoute>
                  <MainController>
                    <Routes>
                      <Route path="/" exact element={<HomeController />} />

                      <Route path="/cdu" exact element={<CDUListController />} />

                      <Route path="/cdu/:cduAccessCode" element={<CDUEditController />} />

                      <Route path="/user" element={<UserProfileController />} />

                      <Route path="/vacation" element={<VacationHomeController />} />

                      <Route path="/assignments" element={<AssignmentsHomeController />} />

                      <Route path="/group-creation" element={<GroupCreatorController />} />

                      <Route path="/group-remover" element={<GroupRemoverController />} />

                      <Route path="/offboarding" element={<OffboardingController />} />

                      <Route path="/coverage" element={<CoverageHomeController />} />

                      <Route path="/bulk-assignment" element={<BulkAssignmentController />} />

                      <Route path="/user-checker" element={<UserCheckerController />} />
                    </Routes>
                  </MainController>
                </SecureRoute>
              } />
            </Routes>
          </BrowserRouter>
          <IconLibrary />
        </ModalContext>
      </ToastContext>
    </AuthContext>
  );
};

export default App;
