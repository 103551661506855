/**
 * Endpoint for removing users from a group
 *
 * Copyright (C) 2022A, Inc.
 * @author nikola
 */

import axios from "axios";
import { getAuthorizationHeader } from "@noom/hive-auth";

export async function removeUserFormGroupAPI(
  groupId: number,
  userAccessCode: string
) {
  return await axios({
    url: `/users/${userAccessCode}/leave`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "DELETE",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
    params: {
      groupId,
    },
  });
}
