/**
 * Lower, editable part of the data page
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import moment from "moment";
import React from "react";
import { If } from "@noom/noomscape";
import SheetCustomField from "components/sheet/SheetCustomField";
import SheetTextField from "components/sheet/SheetTextField";
import Weekdays from "components/etc/Weekdays";
import WhitePartTableau from "views/cdu/CDUEditPage/WhitePartTableau";

import "./WhitePart.scss";

const WhitePart = ({ cdu, onOpenForm }) => (
  <div className="whitePart-box">
    <div className="landlookGrid-box landlookSheet-box">
      <div className="whitePart-menu">
        <button className="whitePart-menuButton" onClick={onOpenForm}>
          EDIT
        </button>
      </div>

      <div className="landlookGrid-row">
        <div className="landlookGrid-column4">
          <SheetCustomField
            label="Whether in ramp up or not"
            hasValue={cdu.employment && cdu.employment.isInRampUp()}
          >
            <If
              if={cdu.employment && cdu.employment.isInRampUp()}
              then={() => (
                <span>
                  Yes
                  <If
                    if={moment(cdu.employment.dateStarted).isBefore(
                      moment().add(-4, "weeks")
                    )}
                    then={() => <span>&nbsp; (finished)</span>}
                  />
                </span>
              )}
              else={() => <span>No</span>}
            />
          </SheetCustomField>
        </div>
        <div className="landlookGrid-column4">
          <SheetCustomField label="Full Time or not">
            <If
              if={cdu.isPerDiem()}
              then={() => <span>No</span>}
              else={() => <span>Yes</span>}
            />
          </SheetCustomField>
        </div>
        <div className="landlookGrid-column4">
          <SheetCustomField
            label="Schedule"
            hasValue={
              !!cdu.schedule || cdu.isPerDiem() || !!cdu.upcomingSchedule
            }
          >
            <If
              if={cdu.isPerDiem()}
              then={() => <span>Per Diem</span>}
              else={() => (
                <If
                  if={cdu.schedule && cdu.schedule.isActive()}
                  then={() => <Weekdays schedule={cdu.schedule} />}
                />
              )}
            />
          </SheetCustomField>
          <If
            if={cdu.upcomingSchedule}
            then={() => (
              <SheetCustomField label="Upcoming">
                <Weekdays schedule={cdu.upcomingSchedule} />
              </SheetCustomField>
            )}
          />
        </div>
        <div className="landlookGrid-column4">
          <SheetTextField
            label="Language hired for"
            value={cdu.employment && cdu.employment.language}
          />
        </div>
      </div>

      <div className="landlookGrid-hr" />

      <div className="landlookGrid-row">
        <div className="landlookGrid-column6">
          <If
            if={cdu.baselineTableau}
            then={() => (
              <WhitePartTableau tableau={cdu.baselineTableau} baseline={true} />
            )}
            else={() => (
              <SheetTextField label="Baseline allocation" value={null} />
            )}
          />
        </div>
        <div className="landlookGrid-column6">
          <If
            if={cdu.temporaryTableau}
            then={() => (
              <WhitePartTableau
                tableau={cdu.temporaryTableau}
                baseline={false}
              />
            )}
          />
        </div>
      </div>
    </div>
  </div>
);

export default WhitePart;
