/**
 * CDU data page
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import CDU from "model/items/CDU";
import { ApiRequest, Waiter, If } from "@noom/noomscape";

import GrayPart from "views/cdu/CDUEditPage/GrayPart";
import GrayForm from "views/cdu/CDUEditPage/GrayForm";
import WhitePart from "views/cdu/CDUEditPage/WhitePart";
import WhiteForm from "views/cdu/CDUEditPage/WhiteForm";

import "./CDUEditPage.scss";

class CDUEditPage extends React.Component {

  static propTypes = {
    cdu: PropTypes.instanceOf(CDU).isRequired,
    formData: PropTypes.object,
    updateRequest: PropTypes.instanceOf(ApiRequest),

    onGoBack: PropTypes.func.isRequired,
    onOpenForm: PropTypes.func.isRequired,
    onCancelForm: PropTypes.func.isRequired,
    onSaveForm: PropTypes.func.isRequired,

    onInputChange: PropTypes.func.isRequired,
    onNumberChange: PropTypes.func.isRequired,
    onValueChange: PropTypes.func.isRequired,
    onToggleChange: PropTypes.func.isRequired,
    onCreateTemporaryTableau: PropTypes.func.isRequired,
    onRemoveTemporaryTableau: PropTypes.func.isRequired,
  };

  render = () => (
    <div className="landlookLayout-scrollPage">
      <div className="landlookLayout-maxWrap">
        <div className="landlookGrid-box landlookSheet-box">

          <div className="landlookGrid-row">
            <div className="landlookGrid-column12">
              <div
                onClick={ this.props.onGoBack }
                className="landlookBlueButton cduEditPage-backButton"
              >
                Back
              </div>
            </div>
          </div>

          <div className="landlookGrid-row">
            <div className="landlookGrid-column12">
              <div className="landlookSheet-title">
                { this.props.cdu.name }
              </div>
            </div>
          </div>

          <If
            if={ this.props.formData?.open && this.props.cdu.isBrandNew() }
            then={ () => (
              <GrayForm
                cdu={ this.props.cdu }
                formData={ this.props.formData }

                onInputChange={ this.props.onInputChange }
                onNumberChange={ this.props.onNumberChange }
                onValueChange={ this.props.onValueChange }
                onToggleChange={ this.props.onToggleChange }
              />
            ) }
            else={ () => (
              <GrayPart
                cdu={ this.props.cdu }
              />
            ) }
          />

        </div>

        <Waiter
          request={ this.props.updateRequest }
          waiting={ () => (

            <If
              if={ this.props.formData?.open }
              then={ () => (
                <WhiteForm
                  cdu={ this.props.cdu }
                  formData={ this.props.formData }
                  onCancel={ this.props.onCancelForm }
                  onSave={ this.props.onSaveForm }

                  onInputChange={ this.props.onInputChange }
                  onNumberChange={ this.props.onNumberChange }
                  onValueChange={ this.props.onValueChange }
                  onToggleChange={ this.props.onToggleChange }
                  onCreateTemporaryTableau={ this.props.onCreateTemporaryTableau }
                  onRemoveTemporaryTableau={ this.props.onRemoveTemporaryTableau }
                />
              ) }
              else={ () => (
                <WhitePart
                  cdu={ this.props.cdu }
                  onOpenForm={ this.props.onOpenForm }
                />
              ) }
            />

          ) }
          loading={ () => (
            <div className="whitePart-box">
              <div className="tinyLoad"><img src="/assets/img/tinyload.svg"/></div>
            </div>
          ) }
          successAs='waiting'
          errorAs='waiting'
        />
      </div>
    </div>
  );
}


export default CDUEditPage;
