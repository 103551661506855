/**
 * Endpoint for fetching active CDU employment
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";
import Schedule from "model/items/Schedule";

Api.register("cduDataSchedules", ({ cduAccessCode }) => {
  return axios({
    url: `/coaches/cdus/${cduAccessCode}/schedules`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then((response) => {
    return _.map(response.data.result, (raw) =>
      _.pick(raw, Schedule.properties)
    );
  });
});
