/**
 * Current user profile information model.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";


class UserProfile {

  static properties = [
    "accessCode",
    "dashboardLanguage",
    "email",
    "firstName",
    "isActive",
    "isExternal",
    "lastName",
    "permissions",
    "admin",
    "assignmentRotationsManager",
    "cduManager",
    "ufcManager",
  ];

  constructor(raw) {
    _.assign(this, _.pick(raw, UserProfile.properties));
  }

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  isAssignmentRotationsManager() {
    return this.isAssignmentRotationsManager || this.admin;
  }

}

export default UserProfile;
