/**
 * Endpoint for logging in
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import axios from "axios";
import qs from "qs";
import sha512 from "js-sha512";

import { Api } from "@noom/noomscape";

Api.register("userLogin", ({ email, password }) => {
  return axios({
    url: "/oauth/token",
    baseURL: process.env.HIVE_SERVER_URL,
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qs.stringify({
      client_id: "coach-dashboard",
      grant_type: "password",
      request: JSON.stringify({
        mode: "LOGIN",
        type: "EMAIL_AND_PASSWORD",
        email: email,
        principal: email,
        credentials: sha512(password),
      }),
    }),
  }).then((response) => {
    const { accessCode, access_token, principal } = response.data;
    return {
      accessCode,
      accessToken: access_token,
      email: principal,
    };
  });
});
