/**
 * Item in the CDU list
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { If } from "@noom/noomscape";

import CDU from "model/items/CDU";
import Highlight from "components/etc/Highlight";
import Tableau from "model/items/Tableau";


const CDUListItem = ({item, index, style, highlight}) => {
  return (
    <div
      style={ style }
      className="landlookListItem-box"
    >
      <div className="landlookListItem-content">
        <Link
          to={ `/cdu/${item.accessCode}` }
          className="landlookListItem-column landlookListItem-link landlookListColumn-5"
        >
          <If
            if={ !item.baselineTableau && (item.isActive !== false) }
            then={ () => (
              <div className="landlookListItem-indicator">
                <div className="landlookListItem-indicatorInner"/>
              </div>
            ) }
          />
          <Highlight
            text={ item.name }
            highlight={ highlight }
          />
        </Link>
        <div className="landlookListItem-column landlookListColumn-4">
          <Highlight
            text={ item.email }
            highlight={ highlight }
          />
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          <Highlight
            text={ item.accessCode }
            highlight={ highlight }
          />
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          <Highlight
            text={ item.managerName }
            highlight={ highlight }
          />
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          { item.employmentStartDate }
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          { item.role
            ? Tableau.typeInfo[item.role].label
            : (item.tableau && item.tableau.topResponsibilityName)
          }
        </div>
        <div className="landlookListItem-column landlookListColumn-1">
          { item.topLanguageName }
        </div>
      </div>
    </div>
  );
};

CDUListItem.propTypes = {
  item: PropTypes.instanceOf(CDU).isRequired,
};


export default CDUListItem;
