/**
 * View for the group creator layout.
 *
 * Copyright (C) 2019D Noom, Inc.
 * @author nikola
 */

import React from "react";
import PropTypes from "prop-types";

import { ScrollingLog } from "modules/tools";

import "./GroupCreatorLayout.scss";

const GroupCreatorLayout = (props) => {
  const {
    value,
    onChange,
    onCreate,
    onReset,
    onRetryAll,
    onRetryFailed,
    isValid,
    inProgress,
    showReport,
    log,
    failed,
  } = props;

  return (
    <div className="landlookLayout-scrollPage groupCreatorLayout-box">
      <div className="landlookLayout-maxWrap">
        <div className="landlookGrid-box landlookSheet-box">
          <div className="landlookGrid-row">
            <div className="landlookGrid-column12">
              <div className="landlookSheet-title">Mass Group Creation</div>
            </div>
          </div>

          <div className="landlookSheet-field">
            <div className="landlookSheet-label">
              Add group data as comma separated rows in the format below.
            </div>

            <div className="landlookSheet-label">
              <b>Format:</b> "Group name" "Group coach (UFC) access code" "First
              user access code" "Second user access code"... Without quotes!
            </div>

            <div className="landlookSheet-label">
              <b>Example:</b>{" "}
              <i>Awesome Group 2C7SW234 1ABEHANC CSH43OT3 FRFIRST2</i>
            </div>

            <div className="groupCreatorLayout-textWrap">
              {inProgress || showReport ? <ScrollingLog log={log} /> : null}

              {!inProgress && !showReport ? (
                <textarea
                  autoFocus
                  className="landlookSheet-input"
                  value={value || ""}
                  onChange={onChange}
                  disabled={inProgress}
                />
              ) : null}

              <div className="groupCreatorLayout-buttonWrap">
                {showReport ? (
                  <>
                    <button onClick={onReset} className="landlookBlueButton">
                      Reset
                    </button>

                    {failed.length > 0 ? (
                      <button
                        onClick={onRetryFailed}
                        className="landlookBlueButton"
                      >
                        Retry failed
                      </button>
                    ) : null}

                    <button onClick={onRetryAll} className="landlookBlueButton">
                      Retry all
                    </button>
                  </>
                ) : null}

                {!showReport ? (
                  <button
                    disabled={!isValid || inProgress}
                    onClick={onCreate}
                    className="landlookBlueButton"
                  >
                    {inProgress ? "Working..." : "Create"}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

GroupCreatorLayout.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onRetryAll: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  inProgress: PropTypes.bool,
  showReport: PropTypes.bool,
  log: PropTypes.array.isRequired,
  failed: PropTypes.array.isRequired,
};

export default GroupCreatorLayout;
