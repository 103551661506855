/**
 * Endpoint for updating CDU data
 *
 * Copyright (C) 2019A Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("cduUpdate", ({ cduAccessCode, data }) => {
  return axios({
    url: `/coaches/cdus/${cduAccessCode}`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "PUT",
    data: _.pick(data, [
      "email",
      "firstName",
      "lastName",
      "isExternal",
      "dashboardLanguage",
      "canCoachExperimentUsers",
      "isActive",
      "accessCode",
      "managerAccessCode",
      "namelyEmployeeId",

      "permissions",
      "admin",
      "namelyDataManager",
      "assignmentRotationsManager",
      "cduManager",
      "ufcManager",
      "cduType",
      "courseLevelCompleted",
    ]),
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return "ok";
    })
    .catch((error) => {
      console.log("UPDATE CDU ERROR", error);
      throw error;
    });
});
