import React from "react";
import { Waiter } from "@noom/noomscape";

import "./AssignmentsSettingsModal.scss";


const AssignmentsSettingsModal = ({
  settings,
  request,
  onToggleAssignmentsAutoMode,
  onDismiss,
}) => (
  <div className="Modal-box AssignmentsSettingsModal-box">
    <div className="AssignmentsSettingsModal-body">
      <div className="AssignmentsSettingsModal-line">
        <div className="AssignmentsSettingsModal-note">
          Auto-assignments are { settings.isAutoModeOn ? "ON" : "OFF" }
        </div>
        <Waiter
          request={ request }
          waiting={ () => (
            <div
              className="AssignmentsSettingsModal-toggleButton"
              onClick={ onToggleAssignmentsAutoMode(!settings.isAutoModeOn) }
            >
              Set to { settings.isAutoModeOn ? "OFF" : "ON" }
            </div>
          ) }
          loading={ () => (
            <div className="tinyLoad"><img src="/assets/img/tinyload.svg"/></div>
          ) }
          successAs='waiting'
          errorAs='waiting'
        />
      </div>
    </div>
    <div className="AssignmentsSettingsModal-footer">
      <div
        className="AssignmentsSettingsModal-footerButton"
        onClick={ onDismiss }
      >
        Done
      </div>
    </div>
  </div>
);


export default AssignmentsSettingsModal;
