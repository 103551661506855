/**
 * Model for physical coach (CDU)
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author Hubert
*/


import _ from "lodash";
import moment from "moment";
import { enu, Item } from "@noom/noomscape";
import Tableau from "model/items/Tableau";
import Employment from "model/items/Employment";
import Schedule from "model/items/Schedule";


class CDU extends Item {

  static dashboardLanguage = enu(["en", "de", "es", "ko", "ja"]);
  static ability = enu(["USE_CALL_SCRIPTS", "PUSH_CONTENT", "EDIT_CDU_PERMISSIONS"]);

  static properties = [
    // Identification
    "accessCode",
    "firstName",
    "lastName",
    "email",

    // Status
    "isActive",

    // Manager properties
    "managerAccessCode",
    "managerFirstName",
    "managerLastName",

    // Other properties
    "namelyEmployeeId",
    "employmentStartDate",
    "isExternal",
    "dashboardLanguage",
    "languageDistributions",
    "role",
    "canCoachExperimentUsers",
    "cduType",
    "nmcStanding",
    "courseLevelCompleted",

    // Permissions
    "permissions",
    "admin",
    "namelyDataManager",
    "assignmentRotationsManager",
    "cduManager",
    "ufcManager",

    // Data properties (fetched separately)
    "employment",

    "allTableaus",
    "baselineTableau",
    "temporaryTableau",

    "allSchedules",
    "schedule",
    "upcomingSchedule",
  ];

  constructor(raw) {
    super();

    _.assign(this, {
      permissions: [],
    });

    this._setFromRaw(raw);
    this.key = raw.accessCode;
  }

  setFromRaw = (raw) => {
    this._setFromRaw(raw);
    this.markUpdated();
  };

  _setFromRaw = (raw) => {
    _.assign(this, _.pick(raw, CDU.properties));

    /*
      Map and sort arrays
    */
    if(raw.employment) {
      this.employment = new Employment(raw.employment);
    }

    if(raw.allTableaus) {
      this.allTableaus = _(raw.allTableaus)
        .map(raw => new Tableau(raw))
        .sortBy("dateStarted")
        .reverse()
        .value();

      const now = moment();
      this.baselineTableau = undefined;
      this.temporaryTableau = undefined;
      _.forEach(this.allTableaus, tableau => {
        if(tableau.isTemporary && moment(tableau.dateEnded).isAfter(now) && !this.temporaryTableau) {
          this.temporaryTableau = tableau;
        }
        if(!tableau.isTemporary && !moment(tableau.dateEnded).isBefore(now) && !this.baselineTableau) {
          this.baselineTableau = tableau;
        }
      });
    }

    if(raw.allSchedules) {
      this.allSchedules = _(raw.allSchedules)
        .map(raw => new Schedule(raw))
        .sortBy("dateStarted")
        .reverse()
        .value();
      const now = moment();
      this.schedule = undefined;
      this.upcomingSchedule = undefined;
      _.forEach(this.allSchedules, schedule => {
        if(moment(schedule.dateStarted).isAfter(now)) {
          this.upcomingSchedule = schedule;
        }
        if(!this.schedule && !moment(schedule.dateStarted).isAfter(now)) {
          this.schedule = schedule;
        }
      });
    }

    if(raw.languageDistributions) {
      this.languageDistributions = _(raw.languageDistributions).sortBy("percent").reverse().value();
    }

    /*
      Set inferred values
    */

    if(raw.employment) {
      this.employmentStartDate = this.employment.dateStarted;
    }

    if(raw.allTableaus && this.baselineTableau) {
      this.role = this.baselineTableau.coachingRole;
    }

    /*
      Set defaults
    */

    if(!this.dashboardLanguage) {
      this.dashboardLanguage = (raw.dashboardLanguage || "en").toLowerCase();
    }

  };


  /*
    ================================================================================
    Name
  */

  get name() {
    return `${this.firstName} ${this.lastName}`;
  }

  get sortBy() {
    return `${this.lastName} ${this.firstName}`;
  }

  /*
    ================================================================================
    Manager
  */

  get managerName() {
    if(!(this.managerFirstName || this.managerLastName)) {
      return null;
    }
    return _.trim(`${this.managerFirstName || ""} ${this.managerLastName || ""}`);
  }

  /*
    ================================================================================
    Other
  */

  isBrandNew() {
    return !this.namelyEmployeeId || !this.employmentStartDate;
  }

  isPerDiem() {
    return !!(this.employment && (!this.employment.hasFixedSchedule));
  }

  get topLanguageName() {
    return (this.languageDistributions &&
            this.languageDistributions[0] &&
            this.languageDistributions[0].language
    ) || "NONE";
  }

}

export default CDU;
