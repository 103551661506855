/**
 * Item in the assignments list
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import React from "react";
import { Waiter } from "@noom/noomscape";
import PropTypes from "prop-types";
import classnames from "classnames";
import defaultTo from "lodash/defaultTo";

import Assignment from "model/items/Assignment";
import Highlight from "components/etc/Highlight";
import CDUSearchFilter from "model/utils/CDUSearchFilter";

const AssignmentsListItem = ({
  item,
  formData,
  updateRequests,
  style,
  highlight,
  onBoolChange,
  onNumberChange,
  onTextChange,
  onDisplayAllocations,
  redirectToCoachAdminUrl
}) => {
  return (
    <div style={style} className="landlookListItem-box">
      <div className="landlookListItem-content">
        <div className="landlookListItem-column landlookListItem-title landlookListColumn-3">
          <Highlight text={item.name} highlight={highlight} />
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          <Highlight text={item.cduAccessCode} highlight={highlight} />
        </div>
        <div className="landlookListItem-column landlookListColumn-4">
          <Highlight text={item.email} highlight={highlight} />
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          <select
            value={item.programLocale}
            onChange={onTextChange(item.cduAccessCode, "programLocale")}
          >
            {_.map(CDUSearchFilter.locale, (locale) => (
              <option value={locale} key={locale}>
                {CDUSearchFilter.localeToValue[locale] ?? "none"}
              </option>
            ))}
          </select>
        </div>
        <div className="landlookListItem-column landlookListColumn-3">
          <select
            value={item.programPackage}
            onChange={onTextChange(item.cduAccessCode, "programPackage")}
          >
            {_.map(CDUSearchFilter.package, (pkg) => (
              <option value={pkg} key={pkg}>
                {CDUSearchFilter.packageToValue[pkg] ?? "none"}
              </option>
            ))}
          </select>
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          <select
            value={item.coursePack ? item.coursePack : "None"}
            onChange={onTextChange(item.cduAccessCode, "coursePack")}
          >
            {Object.keys(CDUSearchFilter.coursePack).map((pack) => {
              return (
                <option value={pack} key={pack}>
                  {CDUSearchFilter.coursePackToValue[pack] ?? "none"}
                </option>
              );
            })}
          </select>
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          {item.caseload}
        </div>
        <div className="landlookListItem-column landlookListColumn-1">
          {item.fteCount * 100}%
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          <button
            className="landlookListItem-button"
            onClick={onDisplayAllocations(item.cduAccessCode)}
          >
            Allocations
          </button>
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          <select
            value={item.businessType}
            onChange={onTextChange(item.cduAccessCode, "businessType")}
          >
            {_.map(
              _.omit(
                CDUSearchFilter.businessType,
                CDUSearchFilter.businessType.ALL
              ),
              (type) => (
                <option value={type} key={type}>
                  {CDUSearchFilter.businessTypeToValue[type] ?? "none"}
                </option>
              )
            )}
          </select>
        </div>
        <div className="landlookListItem-column landlookListColumn-2 landlookListItem-form">
          <Waiter
            request={updateRequests.get(`${item.cduAccessCode}.isInRotation`)}
            waiting={() => (
              <div
                className="landlookFormCheckbox-box"
                onClick={onBoolChange(item.cduAccessCode, "isInRotation")}
              >
                <div
                  className={classnames({
                    "landlookFormCheckbox-checkbox": true,
                    active: defaultTo(
                      formData[item.cduAccessCode]?.isInRotation,
                      item.isInRotation
                    ),
                  })}
                />
              </div>
            )}
            loading={() => (
              <div className="tinyLoad">
                <img src="/assets/img/tinyload.svg" />
              </div>
            )}
            successAs="waiting"
            errorAs="waiting"
          />
        </div>
        <div className="landlookListItem-column landlookListColumn-2 landlookListItem-form">
          <Waiter
            request={updateRequests.get(`${item.cduAccessCode}.maxCaseload`)}
            waiting={() => (
              <div className="landlookFormField-box">
                <input
                  min="0"
                  type="number"
                  value={String(
                    defaultTo(
                      formData[item.cduAccessCode]?.maxCaseload,
                      item.maxCaseload
                    )
                  )}
                  onChange={onNumberChange(item.cduAccessCode, "maxCaseload")}
                  className="landlookFormField-field"
                />
              </div>
            )}
            loading={() => (
              <div className="tinyLoad">
                <img src="/assets/img/tinyload.svg" />
              </div>
            )}
            successAs="waiting"
            errorAs="waiting"
          />
        </div>
        <div className="landlookListItem-column landlookListColumn-2 landlookListItem-form">
          <Waiter
            request={updateRequests.get(
              `${item.cduAccessCode}.maxNewCoacheesPerDay`
            )}
            waiting={() => (
              <div className="landlookFormField-box">
                <input
                  min="0"
                  type="number"
                  value={String(
                    defaultTo(
                      formData[item.cduAccessCode]?.maxNewCoacheesPerDay,
                      item.maxNewCoacheesPerDay
                    )
                  )}
                  onChange={onNumberChange(
                    item.cduAccessCode,
                    "maxNewCoacheesPerDay"
                  )}
                  className="landlookFormField-field"
                />
              </div>
            )}
            loading={() => (
              <div className="tinyLoad">
                <img src="/assets/img/tinyload.svg" />
              </div>
            )}
            successAs="waiting"
            errorAs="waiting"
          />
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          {item.coachType}
        </div>
        <div className="landlookListItem-column landlookListColumn-2">
          <a href={`${redirectToCoachAdminUrl}`}>
            {item.assignToUfcAccessCode}
          </a>
        </div>
      </div>
    </div>
  );
};

AssignmentsListItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.instanceOf(Assignment).isRequired,
};

export default AssignmentsListItem;
