/**
 * Component for displaying selection of weekdays.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";
import React from "react";
import classnames from "classnames";
import "./Weekdays.scss";


const days = [
  {day: "monday", label: "M"},
  {day: "tuesday", label: "T"},
  {day: "wednesday", label: "W"},
  {day: "thursday", label: "T"},
  {day: "friday", label: "F"},
  {day: "saturday", label: "S", weekend: true},
  {day: "sunday", label: "S", weekend: true},
];

const Weekdays = ({
  schedule,
  onChange,
  keyPrefix,
  editMode,
}) => (
  <div
    className={ classnames({
      "weekdays-box": 1,
      "weekdays-edit": editMode,
    }) }
  >
    { _.map(days, day => (
      <div
        key={ day.day }
        className={ classnames({
          "weekdays-day": 1,
          "weekdays-weekend": day.weekend,
          "active": schedule[day.day],
        }) }
        onClick={ editMode ? onChange(`${keyPrefix}.${day.day}`) : () => {} }
      >
        { day.label }
      </div>
    )) }
  </div>
);


export default Weekdays;
