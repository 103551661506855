/**
 * Endpoint for updating assignment data
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("cduDataRemoveTableau", ({ cduAccessCode, tableauUuid }) => {
  return axios({
    url: `/coaches/cdus/${cduAccessCode}/responsibilityAllocations/${tableauUuid}`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "DELETE",
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return "ok";
    })
    .catch((error) => {
      console.log("REMOVE TABLEAU ERROR", error);
      throw error;
    });
});
