/**
 * Component for higlighting search results in text.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";
import React from "react";

import "./Highlight.scss";


class Highlight extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      chunks: this.prepare(props),
    };

  }

  componentWillReceiveProps(newProps) {
    this.setState({
      chunks: this.prepare(newProps),
    });
  }


  prepare(props) {
    if(!props.highlight || props.highlight.length === 0) {
      return [{text: props.text}];
    }
    const regexp = new RegExp(`(${props.highlight})`, "i");
    return (props.text || "")
      .split(regexp)
      .map(t => ({
        text: t,
        highlight: regexp.test(t),
      }));
  }


  render() {
    return (
      <span className="Highlight-box">
        { _.map(this.state.chunks, (chunk, idx) => (
          <span
            key={ idx }
            className={ chunk.highlight ? "Highlight-highlight" : "Highlight-text" }
          >
            { chunk.text }
          </span>
        )) }
      </span>
    );
  }

}


export default Highlight;
