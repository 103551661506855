/**
 * Collection for assignment rotations
 *
 * Copyright (C) 2018B Noom, Inc.
 * @author Hubert
 */

import _ from "lodash";
import { Collection, DevTools, Api } from "@noom/noomscape";

import Assignment from "model/items/Assignment";

class AssignmentsCollection extends Collection {
  constructor() {
    super();
    Assignment.store = this;
  }

  lists = {};

  clear() {
    super.clear();
    this.lists = {};
  }

  /*
    ============================================================
    Fetchers
  */

  fetchWithFilter = ({ filter }) => {
    const f = filter.toString();
    if (this.lists[f]) {
      return;
    }

    Api.call("assignments.search", Api.api.assignmentsSearch, { filter })
      .then((data) => {
        const list = [];
        data.forEach((raw) => {
          const assignment = this.insert(new Assignment(raw));
          list.push(assignment.key);
        });
        this.lists[f] = list;
        this.clearCache(f);
        this.emitChange();
      })
      .catch((error) => {
        console.log("ASSIGNMENT FILTER ERROR", error);
      });
  };

  /*
    ============================================================
    Getters
  */

  getWithFilter = ({ filter }) => {
    const f = filter.toString();
    if (!this.cache[f]) {
      this.initCache({
        cacheKey: f,
        itemList: _.map(this.lists[f], (key) => this.items[key]),
      });
    }
    return this.cache[f];
  };

  /*
    ============================================================
    Updaters
  */

  update = ({ cduAccessCode, data, key }) => {
    return Api.call(
      `assignments.update.${cduAccessCode}.${key}`,
      Api.api.assignmentsUpdate,
      { cduAccessCode, data }
    )
      .then((response) =>
        Api.call(
          `assignments.update.${cduAccessCode}.${key}`,
          Api.api.assignmentsOne,
          { cduAccessCode }
        )
      )
      .then((raw) => {
        const assignment = this.insert(new Assignment(raw));
        this.clearCachesFor(assignment.key);
        this.emitChange();
      })
      .catch((err) => {
        throw err;
      });
  };
}

const instance = new AssignmentsCollection();
DevTools.expose({ AssignmentsCollection: instance });
export default instance;
