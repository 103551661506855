/**
 * Endpoint for updating assignment data
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import _ from "lodash";
import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";
import Tableau from "model/items/Tableau";

Api.register("cduDataUpdateBaselineTableau", ({ cduAccessCode, tableau }) => {
  return axios({
    url: `/coaches/cdus/${cduAccessCode}/responsibilityAllocations/baseline`,
    baseURL: process.env.HIVE_SERVER_URL,
    method: "POST",
    data: {
      responsibilityAllocations:
        Tableau.responsibilityAllocationsFromFormData(tableau),
    },
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return "ok";
    })
    .catch((error) => {
      console.log("UPDATE BASELINE TABLEAU ERROR", error);
      throw error;
    });
});
