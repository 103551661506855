/**
 * View for the bulk assignment tool
 *
 * Copyright (C) 2020C Noom, Inc.
 * @author nikola
 */

import React from "react";
import PropTypes from "prop-types";
import { useBeforeUnload } from "react-use";

import OffboardingForm from "./BulkAssignmentForm";
import { ScrollingLog } from "modules/tools";

import {
  Text,
  ModalConfirm,
  Button,
  VariantColor,
} from "@noom/wax-component-library";

import "./BulkAssignmentLayout.scss";

const BulkAssignmentLayout = (props) => {
  const {
    onSubmit,
    onCancel,
    onStart,
    onReset,
    onRetryAll,
    inProgress,
    showReport,
    log,
    values,
    showConfirmationModal,
  } = props;

  const showForm = !inProgress && !showReport;

  useBeforeUnload(
    inProgress,
    "Closing this screen while the operation is in progress will lead to corrupted data. Are you sure?"
  );

  return (
    <div className="landlookLayout-scrollPage bulkAssignment-box">
      <div className="landlookLayout-maxWrap">
        <div className="landlookGrid-box landlookSheet-box">
          <div className="landlookGrid-row">
            <div className="landlookGrid-column12">
              <div className="landlookSheet-title">Bulk Assignment</div>
            </div>
          </div>

          {showForm ? (
            <OffboardingForm onSubmit={onSubmit} defaultValues={values} />
          ) : (
            <div>
              <ScrollingLog log={log} />

              <div className="bulkAssignment-buttonWrap">
                <Button
                  onClick={onReset}
                  disabled={inProgress}
                  variantColor={VariantColor.secondary}
                >
                  Reset
                </Button>

                <Button
                  onClick={onRetryAll}
                  variantColor={VariantColor.secondary}
                  disabled={inProgress}
                >
                  Retry
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalConfirm
        isOpen={showConfirmationModal}
        onClose={onCancel}
        onConfirm={onStart}
        variantColor={VariantColor.warning}
      >
        <Text as="p">
          Are you sure you want to update assignment for{" "}
          <b>{values.targetCdus?.length ?? 0}</b> CDUs?
        </Text>
      </ModalConfirm>
    </div>
  );
};

BulkAssignmentLayout.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onRetryAll: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  showReport: PropTypes.bool,
  log: PropTypes.array.isRequired,
  failed: PropTypes.array.isRequired,
};

export default BulkAssignmentLayout;
