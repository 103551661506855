/**
 * Endpoint for mass update of UFCs
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import qs from "query-string";
import axios from "axios";
import pickBy from "lodash/pickBy";
import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register(
  "ufc.massUpdate",
  ({ oldCoachAccessCode, newCoachAccessCode, userAccessCodes }) => {
    return axios({
      url: `/usermanagement/coach/${oldCoachAccessCode}/massupdate`,
      baseURL: process.env.HIVE_SERVER_URL,
      method: "POST",
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      params: {
        newCoachAccessCode,
        accessCodes: userAccessCodes,
      },
      paramsSerializer: (params) => {
        return qs.stringify(pickBy(params));
      },
    });
  }
);
