/**
 * Endpoint for fetching assignment data for one CDU
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
 */

import axios from "axios";

import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register("settingsFetchAssignments", () => {
  return axios({
    url: "/assignmentRotations/autoAssignSwitch",
    baseURL: process.env.HIVE_SERVER_URL,
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(),
      "Content-Type": "application/json",
    },
  }).then((response) => ({
    isAutoModeOn: response.data,
  }));
});
