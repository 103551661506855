/**
 * View for the user page.
 *
 * Copyright (C) 2018D Noom, Inc.
 * @author hubert
*/


import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import CDU from "model/items/CDU";


class UserProfilePage extends React.Component {
  static propTypes = {
    profile: PropTypes.instanceOf(CDU).isRequired,
  };

  render = () => (
    <div className="landlookLayout-fixedPage">
      <div className="landlookLayout-fixedPageWrap">
        <div className="landlookLayout-tmp">
          <div className="landlookLayout-tmpTitle">{ this.props.profile.name }</div>
        </div>
      </div>
    </div>
  );
}

export default UserProfilePage;
