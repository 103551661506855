/**
 * Endpoint for fetching all the users handled by an UFC
 *
 * Copyright (C) 2020A Noom, Inc.
 * @author nikola
 */

import axios from "axios";
import { Api } from "@noom/noomscape";
import { getAuthorizationHeader } from "@noom/hive-auth";

Api.register(
  "ufc.listUsers",
  ({ ufcAccessCode, includeInactive = true, limit }) => {
    return axios({
      url: `/coaches/${ufcAccessCode}/users/basic_profile`,
      baseURL: process.env.HIVE_SERVER_URL,
      method: "GET",
      headers: {
        Authorization: getAuthorizationHeader(),
      },
      params: {
        includeInactive,
        limit,
      },
    });
  }
);
