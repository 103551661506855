import React from "react";
import { Icon } from "@noom/noomscape";
import classnames from "classnames";

import "./SortingHeader.scss";


const SortingHeader = ({
  children,
  className,
  sortUpKey,
  sortDownKey,
  currentKey,
  onSort,
}) => (
  <div
    className={ classnames(className, {
      "SortingHeader-box": 1,
      "active": currentKey === sortUpKey || currentKey === sortDownKey,
    }) }
    onClick={ () => {
      if(currentKey === sortUpKey) {
        onSort(sortDownKey);
      } else {
        onSort(sortUpKey);
      }
    } }
  >
    { children }
    &nbsp;
    <Icon
      className='SortingHeader-icon'
      icon={
        currentKey === sortUpKey
          ? "accordion-down"
          : (currentKey === sortDownKey
            ? "accordion-up"
            : "sorting")
      }
    />
  </div>
);

export default SortingHeader;
